import React, {useContext, useEffect, useState} from 'react';
import {
  Button, Checkbox, Container, Divider, Dropdown, Accordion, Icon,
  Form, Grid, Header, Image, Input, Label, Menu, Tab, Table, Loader, Message, Modal, Card, Popup
} from 'semantic-ui-react';
import TopBar from '../../util/topbar';
import UserList from "./UserList";
import SideModal from "../../util/SideModal";
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import Api, {STORAGE_KEYS} from "../../util/api";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {
  currencyCode, filterShowHideGoals, getItemsByDateRange,
  getUserFirstName,
  getUserFullName,
  getUserLastName,
  getUserMiddleName,
  goalTargetPercentage,
  handleSetPopupMessage,
  isLargeScreen,
  KYCStateColor,
  KYCStateName,
  NotifyCode,
  searchOnTable, showHideKeys,
  sortItemsByDate,
  statusColor,
  statusName,
  toActualValue,
  toBaseKESValue,
  toBaseValue,
  toReadableDateTime,
  toReadableValue,
  // userCan, userLevels
} from "../../util/HelperFunctions";
import KYCData from "../../components/KYCData";
import UserProfile from "../../components/UserProfile";
import {UserContext} from "../../context/UserProvider";
import {remoteConfig} from "../../util/firebaseConfig";
import TransactionDetails from "../../components/TransactionDetails";
import {currencyOptions} from "../../util/selectOptions";
import axios from "axios";
import {APP_STATE} from "../../services/app.service";
import UserSmileData from "../../components/UserSmileData";
import GoalTransactions from "../../components/GoalTransactions";
import {TimeInput} from "semantic-ui-calendar-react";
import moment from "moment";
import ProductList from "../products/ProductList";

const count = 50
const fileSize = 1024*1024*2
const fileSizeMessage = 'Image must be 2 mb (2048 KB) or less'
const kraPinStartString = 'A0'
const skip_smile_check = false
const Users = (props) => {
  const {user: currentUser} = useContext(UserContext)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editUserModalOpen, setEditUserModalOpen] = useState(false)
  const [viewModalOpen, setViewModalOpen] = useState(false)
  const [showLoadMore, setShowLoadMore] = useState(true)
  const [loadMore, setLoadMore] = useState(false)
  const [loadGoals, setLoadGoals] = useState(false)
  const [users, setUsers] = useState(null)
  const [offset, setOffset] = useState(0)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [kyc_state, setKyc_state] = useState('')
  const [first_name, setFirst_name] = useState('')
  const [middle_name, setMiddle_name] = useState('')
  const [last_name, setLast_name] = useState('')
  const [dob, setDob] = useState(null)
  const [kra_pin, setKra_pin] = useState('')
  const [phone_number, setPhone_number] = useState('')
  const [enabled, setEnabled] = useState(false)
  const [job_status, setJob_status] = useState(null)
  const [goals, setGoals] = useState(null)
  const [goalIndex, setGoalIndex] = useState('')
  const [errorGoals, setErrorGoals] = useState(false)
  const [currentOffset, setCurrentOffset] = useState(0)
  const [activeTab, setActiveTab] = useState(0)
  const [user, setUser] = useState(null)
  // const [networkError, setNetworkError] = useState(false)
  const [searchTable,setSearchTable] = useState("")
  const [searchTableReferrals,setSearchTableReferrals] = useState("")
  const [getUserId,setGetUserId] = useState(null)
  const [getUserDetails,setGetUserDetails] = useState(null)
  // const [getUserNotFound,setGetUserNotFound] = useState(false)
  const [searchUsers,setSearchUsers] = useState(null)
  const [searchUsersText,setSearchUsersText] = useState("")
  const [searchUsersLoader,setSearchUsersLoader] = useState(false)
  const [failure_reason,setFailure_reason] = useState('')
  const [searchByKYCState, setSearchByKYCState] = useState('')
  const [referrals, setReferrals] = useState(null)
  const [referral_earning, setReferral_earning] = useState(0)
  const [referral_deposits, setReferral_deposits] = useState(0)
  const [loadReferrals, setLoadReferrals] = useState(false)
  const [errorReferrals, setErrorReferrals] = useState(false)
  const [referral_code, setReferral_Code] = useState("")
  const [editReferralCodeModalOpen, setEditReferralModalOpen] = useState(false)
  const [claimsModalOpen, setClaimsModalOpen] = useState(false)
  const [popUp, setPopUp] = useState('')
  const [popUpMessage, setPopUpMessage] = useState('')
  const [activeGoalIndex, setActiveGoalIndex] = useState([])
  const [activeGoalBalanceIndex, setActiveGoalBalanceIndex] = useState([])
  const [expandAllGoals, setExpandAllGoals] = useState(false)
  const [activeGoalTransactionIndex, setActiveGoalTransactionIndex] = useState([])
  const [can_refer_others, setCan_refer_others] = useState(null)
  const [number_of_referrals, setNumber_of_referrals] = useState(null)
  const [earning_per_referral, setEarning_per_referral] = useState(null)
  const [referral_currency, setReferral_currency] = useState(null)
  const [redemption_mode, setRedemption_mode] = useState(null)
  const [reEnrollModalOpen, setReEnrollModalOpen] = useState(false)
  const [userFreezeModalOpen, setUserFreezeModalOpen] = useState(false)
  const [resetBalanceModalOpen, setResetBalanceModalOpen] = useState(false)
  const [goalTransactionState, setGoalTransactionState] = useState('')
  const [goalTransactionStateOpen, setGoalTransactionStateOpen] = useState([])
  const [goalTransactionStateClose, setGoalTransactionStateClose] = useState([])
  const [reconcileTransactionModalOpen, setReconcileTransactionModalOpen] = useState(false)
  const [id_type, setId_type] = useState('')
  const [documentFront, setDocumentFront] = useState(null)
  const [documentBack, setDocumentBack] = useState(null)
  const [selfie, setSelfie] = useState(null)
  const [documentModalOpen, setDocumentModalOpen] = useState(false)
  const [resetPhoneModalOpen, setResetPhoneModalOpen] = useState(false)
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false)
  const [addIfaModalOpen, setAddIfaModalOpen] = useState(false)
  const [ifaFirstName, setIfaFirstName] = useState('')
  const [ifaMiddleName, setIfaMiddleName] = useState('')
  const [ifaLastName, setIfaLastName] = useState('')
  const [ifaEmail, setIfaEmail] = useState('')
  const [ifaPhoneNumber, setIfaPhoneNumber] = useState('')
  const [ifaReferralCode, setIfaReferralCode] = useState('')
  const [appState, setAppState] = useState('')
  const [searchUsersKRA, setSearchUsersKRA] = useState([])
  const [usersKRAModalOpen, setUsersKRAModalOpen] = useState(false)
  const [usersIDNumberModalOpen, setUsersIDNumberModalOpen] = useState(false)
  const [showSmileChecks, setShowSmileChecks] = useState(false)
  const defaultSmileCheckData = {
    f: {v: false, m: '', b:'red'},
    m: {v: false, m: '', b:'red'},
    l: {v: false, m: '', b:'red'},
    fn: {v: false, m: '', b:'red'},
    p: {v: false, m: '', b:'red'},
    dob: {v: false, m: '', b:'red'},
    id: {v: false, m: '', b:'red'},
    pin: {v: false, m: '', b:'red'},
    mfn: {v: false, m: '', b:'red'},
  }
  const [smileChecks, setSmileChecks] = useState({...defaultSmileCheckData})
  const [affiliation, setAffiliation] = useState('')
  const [excludeKeys,setExcludeKeys] = useState(['Foreign KYC document / Alien Card uploaded'])
  const [vendors, setVendors] = useState(null)
  const [verificationEventModalOpen, setVerificationEventModalOpen] = useState(false)
  const [filterUserVersion, setFilterUserVersion] = useState(null)

  //Vendor Meta
  const [vendorId, setVendorId] = useState('')
  /*const [account_name, setAccount_name] = useState('')
  const [account, setAccount] = useState('')
  const [bank, setBank] = useState('')
  const [mobile_money, setMobile_money] = useState('')
  const [apply_interest, setApply_interest] = useState(false)
  const [deposit_maturity, setDeposit_maturity] = useState(0)
  const [deposit_cut_off, setDeposit_cut_off] = useState(null)
  const [withdrawal_maturity, setWithdrawal_maturity] = useState(0)
  const [withdrawal_cut_off, setWithdrawal_cut_off] = useState(null)
  const [interest_trunc_limit, setInterest_trunc_limit] = useState('')*/

  const [popUpList, setPopUpList] = useState([])
  const [popUpMessageList, setPopUpMessageList] = useState({})

  const [loadIds, setLoadIds] = useState([])
  const [migrateToCoopModalOpen, setMigrateToCoopModalOpen] = useState(false)

  const [products, setProducts] = useState(null)

  const resetPopUpList = (value) =>{
    setPopUpList(popUpList.filter(e=> e !== value))
    setPopUpMessageList({...popUpMessageList, [value]:''})
  }

  const [showHide, setShowHide] = useState([])

  useEffect(() => {
    if (!users && !vendors) {
      fetchUsers()
      getAppShowHide()
    }
    if (!vendors) {
      fetchVendors()
    }
    if (!products) {
      fetchProducts()
    }
    if(props.match.params.getUser && (!getUserId || vendors)){
      setGetUserId(props.match.params.getUser)
      if (vendors) {
        fetchUserDetail(props.match.params.getUser)
      }
      /*fetchReferrals(props.match.params.getUser)
      fetchGoals(props.match.params.getUser)*/
      setActiveTab(1)
    }else if(props.location.search) {
      setSearchUsersText(props.location.search.replace('?search=',''))
      handleSearchUsers(props.location.search.replace('?search=',''))
      setGetUserDetails({})
      // setActiveTab(2)
    }else if(!getUserDetails && !getUserId) {
      setGetUserDetails({})
    }
  },[vendors])

  const fetchUsers = async (offset = 0) => {
    resetPopUpList(NotifyCode.FETCH_USERS.ERROR)
    try {
      const {data} = await Api.allUsers({offset, count})
      if (data.result.users) {
        if (offset === 0) {
          setUsers(data.result.users)
          setShowLoadMore(data.result.users.length === count)
        } else {
          setUsers([...users, ...data.result.users])
          setShowLoadMore(data.result.users.length > 0 && data.result.users.length === count)
        }
        setOffset(data.result.pagination.offset + count)
      } else {
        setUsers([])
      }
      setLoadMore(false)
      // setNetworkError(false)
    } catch (err) {
      // setNetworkError(true)
      setUsers([])
      // console.log('err', err)
      handleSetPopupMessage(setPopUpList,setPopUpMessageList,resetPopUpList,NotifyCode.FETCH_USERS.ERROR,err.data.error.message,-1,popUpList,popUpMessageList)
    }
  }

  const fetchUserDetail = async (id)=>{
    resetPopUpList(NotifyCode.FETCH_USER.ERROR)
    try {
      const {data} = await Api.getUser({id})
      if(data.result.user){
        const user = data.result.user
        const userUpdate = {...user, fullName: getUserFullName(user), first_name: getUserFirstName(user), last_name: getUserLastName(user), middle_name: getUserMiddleName(user)}
        setGetUserDetails(userUpdate)
        setUser(userUpdate)
      }
      // setGetUserNotFound(false)
      fetchReferrals(props.match.params.getUser)
      fetchGoals(props.match.params.getUser)
    }catch (err) {
      // setGetUserNotFound(true)
      setGetUserDetails(false)
      // console.log(err)
      handleSetPopupMessage(setPopUpList,setPopUpMessageList,resetPopUpList,NotifyCode.FETCH_USER.ERROR,err.data.error.message,-1,popUpList,popUpMessageList)
      // handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.FETCH_USER.ERROR,err.data.error.message,-1)
    }
  }

  const fetchGoals = async (user_id) =>{
    setLoadGoals(true)
    resetPopUpList(NotifyCode.FETCH_GOALS.ERROR)
    try{
      const {data} = await Api.searchGoal({user_id})
      if(data.result.goals){
        setGoals(data.result.goals)
      }else{
        setGoals([])
      }
      // handleViewUserGoalsModal()
      setErrorGoals(false)
    }catch (err) {
      // console.log(err)
      setErrorGoals(true)
      handleSetPopupMessage(setPopUpList,setPopUpMessageList,resetPopUpList,NotifyCode.FETCH_GOALS.ERROR,err.data.error.message,-1,popUpList,popUpMessageList)
      // handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.FETCH_GOALS.ERROR,err.data.error.message,-1)
    }
    setLoadGoals(false)
  }

  const fetchReferrals = async (user_id) =>{
    setLoadReferrals(true)
    resetPopUpList(NotifyCode.FETCH_REFERRALS.ERROR)
    try{
      const {data} = await Api.allReferral({user_id})
      if(data.result.referrals){
        setReferrals(data.result.referrals)
        setReferralDetails(data.result.referrals)
      }else{
        setReferrals([])
      }
      setErrorReferrals(false)
    }catch (err) {
      console.log(err)
      setErrorReferrals(true)
      handleSetPopupMessage(setPopUpList,setPopUpMessageList,resetPopUpList,NotifyCode.FETCH_REFERRALS.ERROR,err.data.error.message,-1,popUpList,popUpMessageList)
      // handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.FETCH_REFERRALS.ERROR,err.data.error.message,-1)
    }
    setLoadReferrals(false)
  }

  const fetchVendors = async () => {
    resetPopUpList(NotifyCode.FETCH_VENDORS.ERROR)
    try {
      const {data} = await Api.allVendors()
      if (data.result.vendors && data.result.vendors.length) {
        setVendors(data.result.vendors)
      } else {
        setVendors([])
      }
    } catch (err) {
      // console.log(err)
      handleSetPopupMessage(setPopUpList,setPopUpMessageList,resetPopUpList,NotifyCode.FETCH_VENDORS.ERROR,err.data.error.message,-1,popUpList,popUpMessageList)
      // handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.FETCH_VENDORS.ERROR,err.data.error.message,-1)
    }
  }

  const fetchProducts = async () => {
    resetPopUpList(NotifyCode.FETCH_PRODUCTS.ERROR)
    try {
      const {data} = await Api.allProducts()
      if (data.result.products && data.result.products.length) {
        setProducts(data.result.products)
      } else {
        setProducts([])
      }
    } catch (err) {
      handleSetPopupMessage(setPopUpList,setPopUpMessageList,resetPopUpList,NotifyCode.FETCH_PRODUCTS.ERROR,err.data.error.message,-1,popUpList,popUpMessageList)
    }
  }

  const setReferralDetails = (data)=>{
    let earning = 0
    let deposits = 0
    data.forEach(e=>{
      if(e.state === 'actions_completed'){
        earning += e.earning
      }
      deposits += e.deposits
    })
    setReferral_earning(earning)
    setReferral_deposits(deposits)
  }

  const handleGoalFreeze = async (tabGoals,prefix,index) => {
    setGoalIndex(prefix+index)
    try {
      let params = {
        id: tabGoals[index].id,
        enabled: !tabGoals[index].enabled,
      }
      const {data} = await Api.updateGoal(params)
      setGoals([...goals.map(item => item.id === data.result.goal.id ? {...data.result.goal} : item)])
    } catch (err) {
      console.log(err)
    }
    setGoalIndex('')
  }

  const handleSearchUsers = async (q)=>{
    setSearchUsersLoader(true)
    try{
      // const params = {q}
      const {data} = await Api.searchUser({q})
      if(data.result.users){
        setSearchUsers(data.result.users)
      }else {
        setSearchUsers([])
      }
    }catch (err) {
      console.log(err)
    }
    setSearchUsersLoader(false)
  }

  const handleUserUpdate = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    resetPopUp()
    try {
      let params = {
        id: user.id,
        first_name,
        last_name,
        middle_name,
        phone_number,
        enabled,
        dob: !!dob ? formatDob() : null,
        meta:{
          affiliation,
          vendor_data: {
            VendorId: vendorId,
           /* account_name,
            account,
            bank,
            mobile_money,
            apply_interest,
            deposit_maturity: Number(deposit_maturity),
            deposit_cut_off: formatCutOffDate(deposit_cut_off),
            withdrawal_maturity: Number(withdrawal_maturity),
            withdrawal_cut_off: formatCutOffDate(withdrawal_cut_off),
            interest_trunc_limit: Number(interest_trunc_limit),*/
          }
        }
      }
      if (!!(user.meta && user.meta.kyc_data && user.kyc_state === 'data_incomplete')) {
        params = {...params, meta: {...params.meta,kyc_data: {...user.meta.kyc_data, kra_pin}}}
      }

      updateUser(params)
      handleCloseEditUserModal()
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.UPDATE_USER.ERROR,err.data.error.message,-1)
    }
    setSubmitLoader(false)
  }

  const handleKYCData = async (e=null) => {
    e && e.preventDefault()
    setSubmitLoader(true)
    resetPopUp()
    try {
      let params = {
        id: user.id,
        kyc_state,
        meta: {kyc_data: {failure_reason}}
      }
      if (!!(user.meta && user.meta.kyc_data && user.kyc_state === 'data_incomplete')) {
        params = {...params, meta: {kyc_data: {...params.meta.kyc_data,kra_pin}}}
      }
      if (kyc_state === 'verified') {
        params = {...params, skip_smile_check}
      }
      await updateUser(params)
      handleCloseEditModal()
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.UPDATE_KYC.SUCCESS,'Successful')
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.UPDATE_KYC.ERROR,err.data.error.message,-1)
    }
    setLoadIds([...loadIds.filter(e=> e !== 'approve_kyc')])
    setSubmitLoader(false)
  }

  const handleJobStatus = async (e,user) => {
    e.preventDefault()
    setSubmitLoader(true)
    try {
      const {data} = await Api.checkSmileJobStatus({user_id: user.id})
      setJob_status(JSON.parse(data.result.job_status))
    } catch (err) {
      console.log(err)
    }
    setSubmitLoader(false)
  }

  const handleReEnrollUser = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    resetPopUp()
    try {
      let params = {
        id: user.id,
        kyc_state: 'pending_verification'
      }
      updateUser(params)
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.SMILE.SUCCESS,'Successful')
      handleCloseReEnrollModel()
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.SMILE.ERROR,err.data.error.message)
    }
    setSubmitLoader(false)
  }

  const handleFreezeUser = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    resetPopUp()
    try {
      let params = {
        id: user.id,
        frozen: !user.frozen
      }
      updateUser(params)
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.PROFILE.SUCCESS,'Successful')
      handleCloseUserFreezeModal()
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.PROFILE.ERROR,err.data.error.message)
    }
    setSubmitLoader(false)
  }

  const handleResetBalance = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    resetPopUp()
    try {
      let params = {
        id: user.id,
      }
      await Api.resetBalance(params)
      fetchGoals(user.id)

      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.PROFILE.SUCCESS,'Successful')
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.PROFILE.ERROR,err.data.error.message)
    }
    handleCloseResetBalanceModal()
    setSubmitLoader(false)
  }

  const handleResetPhone = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    resetPopUp()
    try {
      let params = {
        id: user.id,
      }
      await Api.resetPhone(params)

      setUsers([...users.map(item => item.id === user.id ? {...user, phone_number: 0} : item)])
      if(searchUsers){
        setSearchUsers([...searchUsers.map(item => item.id === user.id ? {...user, phone_number: 0} : item)])
      }
      if(getUserDetails && getUserDetails.id === user.id){
        setGetUserDetails({...getUserDetails, phone_number: 0})
      }

      setUser({...user, phone_number: 0})

      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.PROFILE.SUCCESS,'Successful')
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.PROFILE.ERROR,err.data.error.message)
    }
    handleCloseResetPhoneModal()
    setSubmitLoader(false)
  }

  const handleDeleteUser = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    resetPopUp()
    try {
      let params = {
        id: user.id,
      }
      await Api.deleteUser(params)
      setUsers([...users.filter(item => item.id !== user.id)])
      if(searchUsers){
        setSearchUsers([...searchUsers.filter(item => item.id !== user.id)])
      }
      if(getUserDetails && getUserDetails.id === user.id){
        // setGetUserNotFound(true)
        setGetUserDetails({})
      }
      handleCloseViewModal()
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.PROFILE.SUCCESS,'Successful')
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.PROFILE.ERROR,err.data.error.message,-1)
    }
    handleCloseDeleteUserModal()
    setSubmitLoader(false)
  }

  const handleMigrateToCoop = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    resetPopUp()
    try {
      let params = {
        id: user.id,
      }
      await Api.migrateToCoop(params)
      updateUserData({...user, frozen: true})
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.PROFILE.SUCCESS,'Successful')
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.PROFILE.ERROR,err.data.error.message)
    }
    handleCloseMigrateToCoopModal()
    setSubmitLoader(false)
  }

  const handleOpenEditModal = (user,state = '', approve=false) => {
    setKyc_state(state ?? user.kyc_state)
    if (approve){
      setLoadIds([...loadIds,'approve_kyc'])
      setTimeout(()=>{
        handleKYCData()
      },1000)
    }
    else {
      setEditModalOpen(true)
      setFailure_reason(user.meta?.kyc_data?.failure_reason ?? '-')
      setKra_pin(user.meta && user.meta.kyc_data && user.kyc_state === 'data_incomplete'?
          user.meta.kyc_data.kra_pin : null)
      setUser(user)
    }

  }

  const handleCloseEditModal = () => {
    setEditModalOpen(false)
    resetPopUp()
  }

  const handleOpenEditUserModal = (user) => {
    setEditUserModalOpen(true)
    setUser(user)
    setFirst_name(user.first_name)
    setMiddle_name(user.middle_name??'')
    setLast_name(user.last_name)
    setPhone_number(user.phone_number??'')
    setEnabled(user.enabled)
    setDob(user.dob? new Date(user.dob): null)
    setKra_pin(user.meta && user.meta.kyc_data && user.kyc_state === 'data_incomplete'?
        user.meta.kyc_data.kra_pin : null)
    setAffiliation(user.meta?.affiliation??'')
    setVendorId(user.meta && user.meta.vendor_data && user.meta.vendor_data.VendorId? user.meta.vendor_data.VendorId : '')
    /*setAccount_name(user.meta && user.meta.vendor_data && user.meta.vendor_data.account_name? user.meta.vendor_data.account_name : '')
    setAccount(user.meta && user.meta.vendor_data && user.meta.vendor_data.account? user.meta.vendor_data.account : '')
    setBank(user.meta && user.meta.vendor_data && user.meta.vendor_data.bank? user.meta.vendor_data.bank : '')
    setMobile_money(user.meta && user.meta.vendor_data && user.meta.vendor_data.mobile_money? user.meta.vendor_data.mobile_money : '')
    setApply_interest(user.meta && user.meta.vendor_data && user.meta.vendor_data.apply_interest? user.meta.vendor_data.apply_interest : '')
    setDeposit_maturity(user.meta && user.meta.vendor_data && user.meta.vendor_data.deposit_maturity? user.meta.vendor_data.deposit_maturity : '')
    setDeposit_cut_off(user.meta && user.meta.vendor_data && user.meta.vendor_data.deposit_cut_off? moment(user.meta.vendor_data.withdrawal_cut_off).format('HH:mm') : '')
    setWithdrawal_maturity(user.meta && user.meta.vendor_data && user.meta.vendor_data.withdrawal_maturity? user.meta.vendor_data.withdrawal_maturity : '')
    setWithdrawal_cut_off(user.meta && user.meta.vendor_data && user.meta.vendor_data.withdrawal_cut_off? moment(user.meta.vendor_data.withdrawal_cut_off).format('HH:mm') : '')
    setInterest_trunc_limit(user.meta && user.meta.vendor_data && user.meta.vendor_data.interest_trunc_limit? user.meta.vendor_data.interest_trunc_limit : '')*/
  }

  const handleCloseEditUserModal = () => {
    setEditUserModalOpen(false)
    resetPopUp()
  }

  const handleOpenViewModal = (user) => {
    resetPopUp()
    setViewModalOpen(true)
    setUser(user)
    setJob_status(null)
    fetchReferrals(user.id)
    fetchGoals(user.id)
    handleShowSmileChecks(user, false)
  }

  const handleCloseViewModal = () => {
    setViewModalOpen(false)
    setJob_status(null)
    setGoalTransactionState('')
    handleShowSmileChecks(user, false)
  }

  const formatDob = () => {
    const year = dob.getFullYear();
    const month = (dob.getMonth().toString().length < 2 && dob.getMonth().toString() !== '9' ? '0' : '')
        + Number(dob.getMonth() + 1)
    const day = (dob.getDate().toString().length < 2 ? '0' : '') + dob.getDate()
    return `${year}-${month}-${day}T00:00:00Z`
  }

  const handleUserGoals = (user) => {
    setLoadGoals(true)
    setUser(user)
    fetchGoals(user.id)
  }

  const viewGoalsTransaction = (index, transactions) => {
    const win = window.open(`/transactions?id=${transactions[index].id}`)
    const state = {
      transactions,
      index
    }
    localStorage.setItem('state',JSON.stringify(state))
    win.focus()
  }

  const refreshBtn = () => {
    setUsers(null)
    fetchUsers()
    fetchVendors()
    fetchProducts()
  }

  const refreshReferralData = (id) => {
    fetchReferrals(id)
  }

  const refreshUserDetailsBtn = () => {
    setGetUserDetails(null)
    fetchUserDetail(getUserId)
  }

  const previousBtn = () => {
    setCurrentOffset(currentOffset - count)
    window.scrollTo(0, 100)
  }

  const nextBtn = async () => {
    if (showLoadMore && (currentOffset + count === offset)) {
      setOffset(offset)
      setLoadMore(true)
      await fetchUsers(offset)
    }
    setCurrentOffset(currentOffset < offset ? currentOffset + count : currentOffset)
    window.scrollTo(0, 100)
  }

  const handleSearchByKYCState = (value)=>{
    if(value){
      setSearchByKYCState(value)
      setSearchUsersText(value)
      handleSearchUsers(value)
    }

  }

  const getKYCReasonList = () =>{
    let kycReasonList = !!remoteConfig.getValue('kyc_reason_list').asString() ?
        JSON.parse(remoteConfig.getValue('kyc_reason_list').asString()):
        [
          "You have not entered a KRA PIN",
          "You have entered an incorrect KRA PIN",
          "You have selected the incorrect ID Type",
          "You have already created a Koa account with the same KRA PIN",
          "You have entered an incorrect ID number",
          "You have entered an incorrect passport number",
          "The system we use to validate identity information does not generate a result when it checks your passport number on the government database. Please use your National ID card instead",
          "You have not entered your residential address. Please update the app and fill in your residential address",
          "The system we use to validate identity information indicates that there is a mismatch in your name as recorded on the government database",
          "Foreign KYC document / Alien Card uploaded"
        ]
    kycReasonList = ["-",...kycReasonList]
    return [...kycReasonList.map(item=> {return {key:item,text:item,value:item}})]
  }

  const setCurrentTab = (value) =>{
    setActiveTab(value)
    if(value === 1 && getUserId){
      if(referrals === null || (referrals.length > 0 && referrals[0].user_id !== getUserId)){
        fetchReferrals(getUserId)
      }
      setUser(getUserDetails)
    }
  }

  const handleUserReferralUpdate = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    resetPopUp()
    try {
      let params = {
        id: user.id,
        referral_code,
        meta:{
          referral_info:{
            can_refer_others,
            number_of_referrals,
            earning_per_referral: toBaseValue(earning_per_referral),
            referral_currency,
            redemption_mode
          }
        }
      }

      updateUser(params)
      handleCloseEditReferralModal()
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.UPDATE_REFERRAL.ERROR,err.data.error.message,-1)
    }
    setSubmitLoader(false)
  }

  const handleOpenEditReferralCodeModal = (user) => {
    setEditReferralModalOpen(true)
    setUser(user)
    setReferral_Code(user.referral_code)
    setCan_refer_others(user.meta && user.meta.referral_info? user.meta.referral_info.can_refer_others : can_refer_others)
    setNumber_of_referrals(user.meta && user.meta.referral_info? user.meta.referral_info.number_of_referrals : number_of_referrals)
    setEarning_per_referral(user.meta && user.meta.referral_info? toActualValue(user.meta.referral_info.earning_per_referral) : earning_per_referral)
    setReferral_currency(user.meta && user.meta.referral_info? user.meta.referral_info.referral_currency : referral_currency)
    setRedemption_mode(user.meta && user.meta.referral_info? user.meta.referral_info.redemption_mode : redemption_mode)
  }

  const handleCloseEditReferralModal = () => {
    setEditReferralModalOpen(false)
    resetPopUp()
  }

  const handleClaimsSubmit = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    resetPopUp()
    try {
      let params = {
        id: user.id
      }

      await Api.claimReferral(params)

      fetchReferrals(user.id)

      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.CLAIM.SUCCESS,'Successful')
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.CLAIM.ERROR,err.data.error.message)
    }
    handleCloseClaimModal()
    setSubmitLoader(false)
  }

  const handleOpenClaimModal = () => {
    setClaimsModalOpen(true)
  }

  const handleCloseClaimModal = () => {
    setClaimsModalOpen(false)
  }

  const getTotalInboundOpen = (transactions) => {
    let total = 0
    if (transactions){
      for(const e of transactions){
        if(e.status === "success" && e.transfer_type === 'transfer' && e.direction === 'inbound'){
            total+= toBaseKESValue(e.amount,e.currency_code)
        }
      }
    }
    return total
  }

  const getTotalInboundClosed = (transactions) => {
    let total = 0
    if (transactions){
      for(const e of transactions){
        if(e.status === "success" && e.transfer_type === 'transfer' && e.direction === 'inbound' && e.state === 'closed'){
            total+= toBaseKESValue(e.amount,e.currency_code)
        }
      }
    }
    return total
  }

  const getTotalOutbound = (transactions) => {
    let total = 0
    if (transactions){
      for(const e of transactions){
        if(e.status === "success" && e.transfer_type === 'transfer' && e.direction === 'outbound'){
            total+= toBaseKESValue(e.amount,e.currency_code)
        }
      }
    }
    return total
  }

  const getTotalOutboundCharge = (transactions) => {
    let total = 0
    if (transactions){
      for(const e of transactions){
        if(e.status === "success" && e.transfer_type === 'transfer' && e.direction === 'outbound' && e.charge){
            total+= toBaseKESValue(e.charge,e.currency_code)
        }
      }
    }
    return total
  }

  const getTotalTransactionInterest = (transactions) => {
    let total = 0
    if (transactions){
      for(const e of transactions){
        if(e.transfer_type === 'interest' && e.direction === 'inbound'){
            total+= toBaseKESValue(e.amount,e.currency_code)
        }
      }
    }
    return total
  }

  const getTotalDeposit = (vendor='') => {
    let total = 0
    for(const e of filterShowHideGoals(goals,showHide)){
      if(!e.deleted_at){
        if(!vendor || vendor === e.vendor_id){
          total+= getTotalGoalTransactionDeposit(e.transactions)
        }
      }
    }

    return total
  }

  const getTotalGoalTransactionDeposit = (transactions) => {
    return  (getTotalInboundOpen(transactions) + getTotalTransactionInterest(transactions)) - (getTotalOutbound(transactions) + getTotalOutboundCharge(transactions))
  }

  const getAllDeposit = (vendor='') => {
    let total = 0
    for(const e of filterShowHideGoals(goals,showHide)){
      if(!e.deleted_at){
        if(!vendor || vendor === e.vendor_id){
          total+= getAllGoalTransactionDeposit(e.transactions)
        }
      }
    }

    return total
  }

  const getAllGoalTransactionDeposit = (transactions) => {
    return getTotalInboundOpen(transactions)
  }

  const getTotalWithdrawable = (vendor='') => {
    let total = 0
    for(const e of filterShowHideGoals(goals,showHide)){
      if(!e.deleted_at){
        if(!vendor || vendor === e.vendor_id){
          total+= getTotalGoalTransactionWithdrawable(e.transactions)
        }
      }
    }

    return total
  }

  const getTotalGoalTransactionWithdrawable = (transactions) => {
    return (getTotalInboundClosed(transactions) + getTotalTransactionInterest(transactions)) - (getTotalOutbound(transactions) + getTotalOutboundCharge(transactions))
  }

  const getAllWithdrawals = (vendor='') => {
    let total = 0
    for (const e of filterShowHideGoals(goals,showHide)) {
      if (!e.deleted_at) {
        if (!vendor || vendor === e.vendor_id) {
          total += getAllGoalTransactionWithdrawals(e.transactions)
        }
      }
    }

    return total
  }

  const getAllWithdrawalCharges = (vendor='') => {
    let total = 0
    for(const e of filterShowHideGoals(goals,showHide)){
      if(!e.deleted_at){
        if(!vendor || vendor === e.vendor_id){
          total+= getAllGoalTransactionWithdrawalCharges(e.transactions)
        }
      }
    }

    return total
  }

  const getAllGoalTransactionWithdrawals = (transactions) => {
    return getTotalOutbound(transactions)
  }

  const getAllGoalTransactionWithdrawalCharges = (transactions) => {
    return getTotalOutboundCharge(transactions)
  }

  const getAllInterest = (vendor='') => {
    let total = 0
    for(const e of filterShowHideGoals(goals,showHide)){
      if(!e.deleted_at){
        if(!vendor || vendor === e.vendor_id){
          total+= getAllGoalTransactionInterest(e.transactions)
        }
      }
    }

    return total
  }

  const getAllDailyInterest = (vendor='') => {
    let total = 0
    for(const e of filterShowHideGoals(goals,showHide)){
      if(!e.deleted_at){
        if(!vendor || vendor === e.vendor_id){
          total+= getAllGoalTransactionInterest(getItemsByDateRange(e.transactions))
        }
      }
    }

    return total
  }

  const getAllGoalTransactionInterest = (transactions) => {
    return getTotalTransactionInterest(transactions)
  }

  const handleActiveGoal= (e, titleProps) => {
    const { index } = titleProps
    const data = activeGoalIndex.includes(index) ? [...activeGoalIndex.filter(item => item !== index)] : [...activeGoalIndex,index]
    setActiveGoalIndex(data)
    if(filterShowHideGoals(goals,showHide).length === data.length){
      setExpandAllGoals(true)
    }
    else {
      setExpandAllGoals(false)
    }
  }

  const handleActiveBalanceGoal= (e, titleProps) => {
    const { index } = titleProps
    const data = activeGoalBalanceIndex.includes(index) ? [...activeGoalBalanceIndex.filter(item => item !== index)] : [...activeGoalBalanceIndex,index]
    setActiveGoalBalanceIndex(data)
  }

  const handleActiveGoalTransaction = (e, titleProps) => {
    const { index } = titleProps
    const data = activeGoalTransactionIndex.includes(index) ? [...activeGoalTransactionIndex.filter(item => item !== index)] : [...activeGoalTransactionIndex,index]
    setActiveGoalTransactionIndex(data)
  }

  const handleExpandAllGoals = (tabGoals,value,prefix) =>{
    if(value){
      const indexes = []
      for (let i = 0; i < tabGoals.length; i++) {
        indexes.push(prefix+i)
      }
      setActiveGoalIndex([...indexes])
    }
    else {
      setActiveGoalIndex([...activeGoalIndex.filter(e=> e.includes(prefix))])
      setActiveGoalTransactionIndex([])
    }
    setExpandAllGoals(value)
  }

  const resetPopUp = () =>{
    setPopUp('')
    setPopUpMessage('')
  }

  const handleOpenReEnrollModel = ()=>{
    setReEnrollModalOpen(true)
  }

  const handleCloseReEnrollModel = ()=>{
    setReEnrollModalOpen(false)
  }

  const handleOpenUserFreezeModal = ()=>{
    setUserFreezeModalOpen(true)
  }

  const handleCloseUserFreezeModal = ()=>{
    setUserFreezeModalOpen(false)
  }

  const handleOpenResetBalanceModal = ()=>{
    setResetBalanceModalOpen(true)
  }

  const handleCloseResetBalanceModal = ()=>{
    setResetBalanceModalOpen(false)
  }

  const handleOpenResetPhoneModal = ()=>{
    setResetPhoneModalOpen(true)
  }

  const handleCloseResetPhoneModal = ()=>{
    setResetPhoneModalOpen(false)
  }

  const handleOpenDeleteUserModal = ()=>{
    setDeleteUserModalOpen(true)
  }

  const handleCloseDeleteUserModal = ()=>{
    setDeleteUserModalOpen(false)
  }

  const handleOpenMigrateToCoopModal = ()=>{
    setMigrateToCoopModalOpen(true)
  }

  const handleCloseMigrateToCoopModal = ()=>{
    setMigrateToCoopModalOpen(false)
  }

  /*const handleCloseResendVerifyAccountModal = ()=>{
    setResendVerifyAccountModalOpen(false)
  }*/

  const handleCancelGoalTransactionState = ()=>{
    setGoalTransactionState('')
    setGoalTransactionStateOpen([])
    setGoalTransactionStateClose([])
  }

  const handleSetGoalTransactionState = (id,state)=>{
    switch (state){
      case 'open':
        setGoalTransactionStateOpen([...goalTransactionStateOpen,id])
        setGoalTransactionStateClose([...goalTransactionStateClose.filter(item => item !== id)])
        break
      case 'closed':
        setGoalTransactionStateClose([...goalTransactionStateClose,id])
        setGoalTransactionStateOpen([...goalTransactionStateOpen.filter(item => item !== id)])
        break
    }
  }

  const handleAllGoalTransactionState = (transactions,state)=>{
    const list = []
    for(const e of transactions){
      list.push(e.id)
    }
    if(state === 'open'){
      setGoalTransactionStateOpen([...list])
      setGoalTransactionStateClose([])
    }
    else {
      setGoalTransactionStateClose([...list])
      setGoalTransactionStateOpen([])
    }
  }

  const handleSaveGoalTransactionState = async (e)=>{
    e.preventDefault()
    setSubmitLoader(true)
    try {
      let params = {
        open: [...goalTransactionStateOpen],
        close: [...goalTransactionStateClose],
      }
      await Api.settleTransaction(params)
      setGoals([...goals.map(e=> e.transactions ?
          {...e, transactions: [...e.transactions.map(ee=>
                    goalTransactionStateOpen.includes(ee.id) ? {...ee,state:'open'}
                : goalTransactionStateClose.includes(e.id)? {...ee,state:'closed'}
                : ee)
            ]
          }
          : e
      )])
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.GOAL.SUCCESS,'Successful',goalTransactionState)
      handleCancelGoalTransactionState()
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.GOAL.ERROR,err.data.error.message,goalTransactionState)
    }
    setSubmitLoader(false)
  }

  const handleOpenReconcileTransactionModal = (transaction)=>{
    setReconcileTransactionModalOpen(true)
  }

  const handleCloseReconcileTransactionModal = ()=>{
    setReconcileTransactionModalOpen(false)
  }

  const handleReconcileTransaction = async (e)=>{
    e.preventDefault()
    setSubmitLoader(true)
    try {
      let params = {
        id: user.id,
      }
      await Api.reconcileTransaction(params)
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.GOAL.SUCCESS,'Successful','goals')
      handleUserGoals(user)
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.GOAL.ERROR,err.data.error.message,'goals')
    }
    handleCloseReconcileTransactionModal()
    setSubmitLoader(false)
  }

  const handleOpenDocumentModal = (user) => {
    setDocumentModalOpen(true)
    setUser(user)
  }

  const handleCloseDocumentModal = () => {
    setDocumentModalOpen(false)
    setDocumentFront(null)
    setDocumentBack(null)
    setSelfie(null)
    setId_type('')
    resetPopUp()
  }

  const handleSetSelfie = (file)=>{
    resetPopUp()

    if(file.size <= fileSize){
      setSelfie(file)
    }
    else{
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.UPDATE_KYC.ERROR,fileSizeMessage,-1)
    }

  }

  const handleSetDocumentFront = (file)=>{
    resetPopUp()
    if(file.size <= fileSize){
      setDocumentFront(file)
    }
    else{
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.UPDATE_KYC.ERROR,fileSizeMessage,-1)
    }
  }

  const handleSetDocumentBack = (file)=>{
    resetPopUp()
    if(file.size <= fileSize){
      setDocumentBack(file)
    }
    else{
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.UPDATE_KYC.ERROR,fileSizeMessage,-1)
    }
  }

  const getFileExtension = (file)=>{
    return file.name.split('.').pop()
  }

  const saveAndUpdateKYCUpload = async (signed_url,result,params)=>{
    setSubmitLoader(true)
    try {
      await axios.put(signed_url,result)
      await updateUser(params)
      handleCloseDocumentModal()
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.UPDATE_KYC.SUCCESS,'Upload Successful')
    }catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.UPDATE_KYC.ERROR,err.data?.error?.message ?? 'Upload Failed','',-1)
    }
    setSubmitLoader(false)
  }

  const updateUser = async (params)=>{
    const {data} = await Api.updateUser(params)
    updateUserData(data.result.user)
    handleCloseEditModal()
  }

  const updateUserData = (user)=>{
    if(getUserDetails && getUserId === user.id){
      setGetUserDetails({...user, fullName: getUserFullName(user)})
    }
    setUser({...user, fullName: getUserFullName(user)})
    setUsers([...users.map(item => item.id === user.id ? {...user, fullName: getUserFullName(user)} : item)])
    if(searchUsers){
      setSearchUsers([...searchUsers.map(item => item.id === user.id ? {...user} : item)])
    }
  }



  const handleUploadDocument = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    resetPopUp()
    try {
      if(selfie){
        const {data} = await Api.upload({doc_type:id_type,extension:getFileExtension(selfie)})
        const params = {
          id: user.id,
          meta: {
            kyc_data: {
              selfie: data.result.key,
            }
          }
        }
        await saveAndUpdateKYCUpload(data.result.signed_url,selfie,params)
      }
      if(documentFront){
        const {data} = await Api.upload({doc_type:id_type,extension:getFileExtension(documentFront)})
        const params = {
          id: user.id,
          meta: {
            kyc_data: {
              id_document: data.result.key,
              id_type: id_type.toUpperCase()
            }
          }
        }
        await saveAndUpdateKYCUpload(data.result.signed_url,documentFront,params)
      }
      if(documentBack){
        const {data} = await Api.upload({doc_type:id_type,extension:getFileExtension(documentBack)})
        const params = {
          id: user.id,
          meta: {
            kyc_data: {
              id_document_back: data.result.key,
              id_type: id_type.toUpperCase()
            }
          }
        }
        await saveAndUpdateKYCUpload(data.result.signed_url,documentBack,params)
      }
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.UPDATE_KYC.ERROR,err.data.error.message,-1)
    }
    setSubmitLoader(false)
  }

  const handleAddIfaModelOpen = () =>{
    setAddIfaModalOpen(true)
  }

  const handleAddIfaModelClose = () =>{
    setAddIfaModalOpen(false)
  }

  const resetAddIfaFields = () =>{
    setIfaFirstName('')
    setIfaMiddleName('')
    setIfaLastName('')
    setIfaEmail('')
    setIfaPhoneNumber('')
    setIfaReferralCode('')
  }

  const handleAddIfaUserSubmit = async (e) => {
    e.preventDefault()
    setSubmitLoader(true)
    try {
      const params = {
        first_name: ifaFirstName,
        middle_name: ifaMiddleName,
        last_name: ifaFirstName,
        email: ifaEmail,
        phone_number: ifaPhoneNumber,
        referral_code: ifaReferralCode,
      }
      const {data} = await Api.addIfa(params)
      setUsers([data.result.user,...users])
      if(searchUsers){
        setSearchUsers([data.result.user,...searchUsers])
      }
      handleAddIfaModelClose()
      resetAddIfaFields()
    } catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.CREATE_IFA_USER.ERROR,err.data?.error?.message ?? 'Failed','',-1)
    }
    setSubmitLoader(false)
  }

  const handleOpenUsersKRAModal = () => {
    setUsersKRAModalOpen(true)
  }

  const handleCloseUsersKRModal = () => {
    setUsersKRAModalOpen(false)
    setSearchUsersKRA([])
    resetPopUp()
  }

  const handleOpenUsersIDNumberModal = () => {
    setUsersIDNumberModalOpen(true)
  }

  const handleCloseUsersIDNumberModal = () => {
    setUsersIDNumberModalOpen(false)
    setSearchUsersKRA([])
    resetPopUp()
  }

  const handleSearchUsersKRA = async (user)=>{
    setAppState(APP_STATE.LOADING_KRA_PIN_CHECKS)
    try{
      const {data} = await Api.searchUser({q: user.meta.kyc_data.kra_pin})
      if(data.result.users.length > 1){
        const result = data.result.users.filter(e=> e.id !== user.id).filter(e=> e.meta.kyc_data?.kra_pin === user.meta.kyc_data.kra_pin)
        if(result.length > 0){
          setSearchUsersKRA(result)
          handleOpenUsersIDNumberModal()
          setAppState(APP_STATE.IDLE)
          return
        }
      }
      setSearchUsersKRA([])
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.KRA_PIN.SUCCESS,'No KRA PIN Duplicates found')
    }catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.KRA_PIN.ERROR,err.data?.error?.message ?? 'Check Failed')
      // console.log(err)
    }
    setAppState(APP_STATE.IDLE)
  }

  const handleSearchUsersIDNumber = async (user)=>{
    setAppState(APP_STATE.LOADING_ID_NUMBER_CHECKS)
    try{
      const {data} = await Api.searchUser({q: user.meta.kyc_data.id_number})
      if(data.result.users.length > 1){
        const result = data.result.users.filter(e=> e.id !== user.id).filter(e=> e.meta.kyc_data?.id_number === user.meta.kyc_data.id_number)
        if(result.length > 0){
          setSearchUsersKRA(result)
          handleOpenUsersIDNumberModal()
          setAppState(APP_STATE.IDLE)
          return
        }
      }
      /*else {
        setSearchUsersKRA([])
        handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.ID_NUMBER.SUCCESS,'No ID Number Duplicates found')
      }*/
      setSearchUsersKRA([])
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.ID_NUMBER.SUCCESS,'No ID Number Duplicates found')
    }catch (err) {
      handleSetPopupMessage(setPopUp,setPopUpMessage,resetPopUp,NotifyCode.ID_NUMBER.ERROR,err.data?.error?.message ?? 'Check Failed')
      // console.log(err)
    }
    setAppState(APP_STATE.IDLE)
  }

  const handleShowSmileChecks = (data, value) => {
    setShowSmileChecks(value)
    if(value && data.meta && data.meta.smile_id_job && data.meta.smile_id_job.callback_response){
      const cr = data.meta.smile_id_job.callback_response
      const mp = data.meta.mpesa_info
      const fullName = cr?.FullName.toLowerCase().trim()
      const sDOB = cr.DOB ? new Date(cr.DOB).getTime(): 1
      const dDOB = data.dob ? new Date(data.dob).getTime(): 0
      const sIDNumber = cr.IDNumber ? cr.IDNumber: 1
      const dIDNumber = data?.meta?.kyc_data?.id_number ? data.meta.kyc_data.id_number: 0
      let res = {...smileChecks}
      const f = fullName.includes(data.first_name.toLowerCase().trim())
      const l = fullName.includes(data.last_name.toLowerCase().trim())
      const m = fullName.split(" ").length > 2 ? data.middle_name && fullName.includes(data?.middle_name.toLowerCase().trim()) : true
      const cm = fullName.split(" ").length > 2 && data.middle_name ? fullName.includes(data.middle_name.toLowerCase().trim()) : true
      const fn = fullName.includes(getUserFullName(data).toLowerCase())
      const mps = mp && mp.account_name ? mp.account_name.split(" ") : []
      const mfn = f && l&& m &&
          (mps.length > 1 ? fullName.includes(mps[0].toLowerCase()) && fullName.includes(mps[1].toLowerCase()) && (mps.length > 2 ? fullName.includes(mps[2].toLowerCase()) : true) : false)
      const dob = sDOB === dDOB
      const id = sIDNumber === dIDNumber
      const pin = data.meta.kyc_data.kra_pin && data.meta.kyc_data?.kra_pin.slice(0,2).includes(kraPinStartString)
      res = {...res,
        f:{b: f? 'green':'red', m: f? 'Match':'Mismatch'},
        l:{b: l? 'green':'red', m: l? 'Match':'Mismatch'},
        m:{b: m? 'green':'red', m: m? 'Match':'Mismatch'},
        mfn:{b: mfn? 'green':'red', m: mfn? 'Pass':'Failed'},
        fn:{b: f && l&& m ? fn? 'green' : 'yellow' :'red', m: f && l&& m ? fn? 'Match' :'Match, different arrangement':'Mismatch'},
        dob:{b: dob? 'green':'red', m: dob? 'Match':'Mismatch'},
        mdob:{b: dob? 'green':'red', m: dob? 'Pass':'Failed'},
        id:{b: id? 'green':'red', m: id? 'Match':'Mismatch'},
        pin:{b: pin? 'green':'red', m: pin? 'Starts with '.concat(kraPinStartString): data.meta.kyc_data.kra_pin ? 'Starts with '.concat(data.meta.kyc_data.kra_pin.slice(0,2)): 'Not Provided'},
      }
      setSmileChecks({...res})
    }
    else {
      setSmileChecks({...defaultSmileCheckData})
    }
  }

  const filterSearchUsers = () => {
    let res = [...searchUsers]
    if(searchByKYCState === 'pending_verification' && searchUsers.length){
      res = [...searchUsers.filter(e=> !excludeKeys.includes(e.meta.kyc_data.failure_reason))
          .sort((a,b)=> new Date(a.meta.smile_id_job.verification_request_date) - new Date(b.meta.smile_id_job.verification_request_date))
          .filter(e=> filterUserVersion === null || e.meta.is_v2_user === filterUserVersion)
      ]
    }

    return res
  }

  const filterTransactionsToTabs = (transactions,type) => {
    switch (type) {
      case 'inbound': return [...transactions.filter(e=> e.direction === type && e.transfer_type !== 'interest')]
      case 'outbound': return [...transactions.filter(e=> e.direction === type)]
      case 'interest': return [...transactions.filter(e=> e.transfer_type === type)]
      case 'success': return [...transactions.filter(e=> e.status === type)]
      case 'pending': return [...transactions.filter(e=> e.status === type)]
      case 'failure': return [...transactions.filter(e=> e.status === type)]
      case 'open': return [...transactions.filter(e=> e.state === type)]
      case 'closed': return [...transactions.filter(e=> e.state === type)]
      default: return transactions
    }
  }

  const handleOpenVerificationEventModal = (user) => {
    setVerificationEventModalOpen(true)
    setUser(user)
  }

  const handleCloseVerificationEventModal = () => {
    setVerificationEventModalOpen(false)
  }

  const viewUser = (id) => {
    const win = window.open(`/users/${id}`)
    win.focus()
  }

  const getAllGoalTransactionsWithStatus = (status = 'pending')=>{
    const transactions = []

    filterShowHideGoals(goals,showHide).filter(e=>e.transactions && e.transactions.length > 0).forEach(e=>
        transactions.push(...e.transactions.filter(ee=> ee.status === status))
    )

    return transactions
  }

  const getAppShowHide =()=>{
    const val = localStorage.getItem(STORAGE_KEYS.SHOW_HIDE)
    if(val){
      const res = JSON.parse(val)
      if(Array.isArray(res)){
        setShowHide(res)
      }
    }
  }

  const setAppShowHide =(value)=>{
    let res = []
    if(showHide.includes(value)){
      res = [...showHide.filter(e=> e !== value)]
    }else{
      res = [...showHide,value]
    }
    setShowHide([...res])
    localStorage.setItem(STORAGE_KEYS.SHOW_HIDE, JSON.stringify(res))
  }

  const setAppShowHideAll =(items=[],hide=false)=>{
    let res = []
    if(hide){
      res = [...showHide,...items]
    }else{
      res = [...showHide.filter(e=> !items.includes(e))]
    }
    console.log(items,res)
    setShowHide([...res])
    localStorage.setItem(STORAGE_KEYS.SHOW_HIDE, JSON.stringify(res))
  }

  const panes = [
    {
      menuItem: 'Users', render: () => <Tab.Pane loading={!users}>
        {(popUpList.includes(NotifyCode.FETCH_VENDORS.ERROR)) && <Grid centered>
          <Grid.Row>
            <Grid.Column width={14}>
              <Message negative>
                <Message.Header>{popUpMessageList[NotifyCode.FETCH_VENDORS.ERROR]}</Message.Header>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>}
        {(popUpList.includes(NotifyCode.FETCH_PRODUCTS.ERROR)) && <Grid centered>
          <Grid.Row>
            <Grid.Column width={14}>
              <Message negative>
                <Message.Header>{popUpMessageList[NotifyCode.FETCH_PRODUCTS.ERROR]}</Message.Header>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>}
        {(popUpList.includes(NotifyCode.FETCH_USERS.ERROR)) ? <Grid centered>
          <Grid.Row>
            <Grid.Column width={14}>
              <Message negative>
                <Message.Header>{popUpMessageList[NotifyCode.FETCH_USERS.ERROR]}</Message.Header>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>: <>
          <Grid centered>
            <Grid.Row>
              <Grid.Column width={7}>
                <div style={{display: 'flex', width: '100%'}}>
                  <Input label="Search" placeholder="Search" value={searchUsersText}
                         loading={searchUsersLoader} disabled={searchUsersLoader}
                         onChange={(e)=>setSearchUsersText(e.target.value)}
                         onKeyDown={e=> e.key === 'Enter' && searchUsersText.length > 0 ? handleSearchUsers(searchUsersText):null}
                         style={{width: '100%',marginRight:3}}
                  />
                  <Button onClick={()=>handleSearchUsers(searchUsersText)} loading={searchUsersLoader} disabled={searchUsersLoader|| searchUsersText.length===0}>Search</Button>
                </div>
              </Grid.Column>
              <Grid.Column width={4}>
                <Dropdown
                    text='Search by KYC State'
                    icon='filter'
                    labeled
                    button
                    className='icon'
                    value={searchByKYCState}
                    options={[
                      {key:null,text:'Search by KYC State',value:null,label: { color: 'black', empty: true, circular: true },},
                      {key:'verified',text:'VERIFIED',value:'verified',label: { color: 'green', empty: true, circular: true },},
                      {key:'auto_verification_passed',text:'AUTO VERIFICATION',value:'auto_verification_passed',label: { color: 'teal', empty: true, circular: true },},
                      {key:'pending_verification',text:'PENDING',value:'pending_verification',label: { color: 'yellow', empty: true, circular: true },},
                      {key:'data_incomplete',text:'FAILED',value:'data_incomplete',label: { color: 'red', empty: true, circular: true },},
                      {key:'needs_human_review',text:'NEEDS REVIEW',value:'needs_human_review',label: { color: 'orange', empty: true, circular: true },},
                      {key:'not_provided',text:'NOT PROVIDED',value:'not_provided',label: { color: 'grey', empty: true, circular: true },},
                    ]}
                    onChange={(event, {value}) => handleSearchByKYCState(value)}
                />
              </Grid.Column>
              {!!searchUsers&&<Grid.Column width={3}>
                <Button icon='close' content='Clear Search' basic onClick={()=>{
                  setSearchUsers(null)
                  setSearchUsersText('')
                  setSearchByKYCState('none')
                }}/>
              </Grid.Column>}
            </Grid.Row>
          </Grid>
          {searchByKYCState === 'pending_verification' && <Grid>
            <Grid.Row>
            <Grid.Column width={8}>
              <Dropdown
                  text='Exclude Reason'
                  icon='filter'
                  labeled
                  multiple
                  button
                  className='icon'
                  value={excludeKeys}
                  options={getKYCReasonList()}
                  onChange={(event, {value}) => setExcludeKeys(value)}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Dropdown
                  text='Version Users'
                  icon='filter'
                  labeled
                  button
                  className='icon'
                  value={filterUserVersion}
                  options={[
                    {key:'null', text: 'All users', value: null},
                    {key:'1', text: 'Britam', value: false},
                    {key:'2', text: 'COOP', value: true},
                  ]}
                  onChange={(event, {value}) => setFilterUserVersion(value)}
              />
            </Grid.Column>
          </Grid.Row>
          </Grid>}
          <UserList data={!!searchUsers? filterSearchUsers():users?.slice(currentOffset, count + currentOffset)}
                    handleView={handleOpenViewModal} searchTable={searchTable} style={{marginTop:10}}/>
          {/*<UserList data={ searchTable.length === 0 ? users?.slice(currentOffset, count + currentOffset) : users}
                      handleView={handleOpenViewModal} searchTable={searchTable}/>*/}
          {/*{searchTable.length === 0 && <>*/}
          {!!!searchUsers && <>
            {currentOffset > 0 && <Button secondary onClick={previousBtn}>Previous</Button>}
            {(showLoadMore || currentOffset + count < offset)  &&
                <Button loading={loadMore} secondary onClick={nextBtn}>Next</Button>}</>}
        </>}
        {/*{!networkError ?
            <>
              <Grid centered>
                <Grid.Row>
                  <Grid.Column width={7}>
                    <div style={{display: 'flex', width: '100%'}}>
                      <Input label="Search" placeholder="Search" value={searchUsersText}
                             loading={searchUsersLoader} disabled={searchUsersLoader}
                             onChange={(e)=>setSearchUsersText(e.target.value)}
                             onKeyDown={e=> e.key === 'Enter' && searchUsersText.length > 0 ? handleSearchUsers(searchUsersText):null}
                             style={{width: '100%',marginRight:3}}
                      />
                      <Button onClick={()=>handleSearchUsers(searchUsersText)} loading={searchUsersLoader} disabled={searchUsersLoader|| searchUsersText.length===0}>Search</Button>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Dropdown
                        text='Search by KYC State'
                        icon='filter'
                        floating
                        labeled
                        button
                        className='icon'
                        value={searchByKYCState}
                        options={[
                          {key:'verified',text:'VERIFIED',value:'verified',label: { color: 'green', empty: true, circular: true },},
                          {key:'pending_verification',text:'PENDING',value:'pending_verification',label: { color: 'yellow', empty: true, circular: true },},
                          {key:'data_incomplete',text:'INCOMPLETE',value:'data_incomplete',label: { color: 'red', empty: true, circular: true },},
                          {key:'not_provided',text:'NOT PROVIDED',value:'not_provided',label: { color: 'grey', empty: true, circular: true },},
                        ]}
                        onChange={(event, {value}) => handleSearchByKYCState(value)}
                    />
                  </Grid.Column>
                  {!!searchUsers&&<Grid.Column width={3}>
                    <Button icon='close' content='Clear Search' basic onClick={()=>{
                      setSearchUsers(null)
                      setSearchUsersText('')
                      setSearchByKYCState('none')
                    }}/>
                  </Grid.Column>}
                </Grid.Row>
              </Grid>
                {searchByKYCState === 'pending_verification' && <Grid><Grid.Row>
                  <Grid.Column width={8}>
                    <Dropdown
                        text='Exclude Reason'
                        icon='filter'
                        labeled
                        multiple
                        button
                        className='icon'
                        value={excludeKeys}
                        options={getKYCReasonList()}
                        onChange={(event, {value}) => setExcludeKeys(value)}
                    />
                  </Grid.Column>
                </Grid.Row></Grid>}
              <UserList data={!!searchUsers? filterSearchUsers():users?.slice(currentOffset, count + currentOffset)}
                        handleView={handleOpenViewModal} searchTable={searchTable} style={{marginTop:10}}/>
              {!!!searchUsers && <>
                {currentOffset > 0 && <Button secondary onClick={previousBtn}>Previous</Button>}
                {(showLoadMore || currentOffset + count < offset)  &&
                <Button loading={loadMore} secondary onClick={nextBtn}>Next</Button>}</>}
            </> :
            <Container text textAlign="center" style={{height: "37vh"}}>
              Network error <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
            </Container>
        }*/}
      </Tab.Pane>
    },
    {
      menuItem: 'User Details', render: () => <Tab.Pane loading={getUserDetails === null}>
        {(popUpList.includes(NotifyCode.FETCH_VENDORS.ERROR)) && <Grid centered>
          <Grid.Row>
            <Grid.Column width={14}>
              <Message negative>
                <Message.Header>{popUpMessageList[NotifyCode.FETCH_VENDORS.ERROR]}</Message.Header>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>}
        {(popUpList.includes(NotifyCode.FETCH_PRODUCTS.ERROR)) && <Grid centered>
          <Grid.Row>
            <Grid.Column width={14}>
              <Message negative>
                <Message.Header>{popUpMessageList[NotifyCode.FETCH_PRODUCTS.ERROR]}</Message.Header>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Button secondary onClick={refreshBtn} icon="redo" content="Refresh" size="tiny" style={{marginLeft: 10}}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>}
        {!!getUserId ?<>
              {(popUpList.includes(NotifyCode.FETCH_REFERRALS.ERROR)) && <Grid centered>
                <Grid.Row>
                  <Grid.Column width={14}>
                    <Message negative>
                      <Message.Header>{popUpMessageList[NotifyCode.FETCH_REFERRALS.ERROR]}</Message.Header>
                    </Message>
                  </Grid.Column>
                </Grid.Row>
              </Grid>}
        {(popUpList.includes(NotifyCode.FETCH_USER.ERROR)) ? <Grid centered>
          <Grid.Row>
            <Grid.Column width={14}>
              <Message negative>
                <Message.Header>{popUpMessageList[NotifyCode.FETCH_USER.ERROR]}</Message.Header>
              </Message>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={14}>
              <Button secondary onClick={refreshUserDetailsBtn} icon="redo" content="Reload" size="tiny"
                      style={{marginLeft: 10}}/>
            </Grid.Column>
          </Grid.Row>
        </Grid> : <>
          {getUserDetails &&
              <Tab menu={{secondary: true, pointing: true}} panes={modalPanes(getUserDetails)}/>
          }
        </>}</>
            /*(!getUserNotFound ?
                <>
                  {getUserDetails !== null && <Tab menu={{ secondary: true, pointing: true }} panes={modalPanes(getUserDetails)}/>}
                </> :
                <Container text textAlign="center" style={{height: "37vh"}}>
                  User not found
                  <Button secondary onClick={refreshUserDetailsBtn} icon="redo" content="Reload" size="tiny" style={{marginLeft: 10}}/>
                </Container>)*/:
            <Container text textAlign="center" style={{height: "37vh"}}>
              No data to show
            </Container>
        }
      </Tab.Pane>
    },
  ]

  const modalPanes = (user) =>{
    // user = {...user, fullName: getUserFullName(user), first_name: getUserFirstName(user), last_name: getUserLastName(user), middle_name: getUserMiddleName(user)}
    let panes = [
      {
        menuItem:<Menu.Item key='profile'>
          Profile <Label
            color={user.enabled?'green':'red'}
            size="medium" content={user.enabled?'Active':'Inactive'} />
        </Menu.Item>, render: () => <Tab.Pane>
          <Grid centered>
            <Grid.Row textAlign="right">
              <Grid.Column
                  width={14} floated="right">
                {/*width={user.meta && user.meta.britam && user.meta.britam.britam_user_id ? 14 : 9} floated="right">*/}
                {/*{ userCan(currentUser.access, userLevels.UPDATE_USER) && */}
                    <Button icon="pencil" content='Update User' size="small" color="blue" onClick={()=>handleOpenEditUserModal(user)} style={{marginBottom: 5}}/>
                {/*}*/}
                {/*{ userCan(currentUser.access, userLevels.FREEZE_USER_ACCOUNT) && */}
                    <Button icon="ban" content={user.frozen ? "Unfreeze Account":"Freeze Account"}
                            size="small" color={user.frozen ? "yellow":"red"} onClick={handleOpenUserFreezeModal} style={{marginBottom: 5}}/>
                {/*}*/}
                {/*{ userCan(currentUser.access, userLevels.RESET_USER_BALANCE) && */}
                    <Button icon="redo" content="Reset Balance" size="small" color="orange" onClick={handleOpenResetBalanceModal} style={{marginBottom: 5}}/>
                {/*}*/}
                {/*{ userCan(currentUser.access, userLevels.RESET_USER_PHONE) && */}
                    <Button icon="redo" content="Reset Phone Number" size="small" color="purple" onClick={handleOpenResetPhoneModal} style={{marginBottom: 5}}/>
                {/*}*/}
                {/*{ (userCan(currentUser.access, userLevels.DELETE_USER) && process.env.REACT_APP_SHOW_DELETE_USER_BUTTON === 'true') &&*/}
                    <Button icon="delete" content="Delete User" size="small" onClick={handleOpenDeleteUserModal} style={{marginBottom: 5}}/>

                {(process.env.REACT_APP_SHOW_MIGRATE_COOP_BUTTON === 'true' && !user.meta.is_v2_user) && <Button icon="arrows alternate horizontal" content="Migrate to COOP" size="small" color="teal"
                         onClick={handleOpenMigrateToCoopModal} style={{marginBottom: 5}}/>}
                {/*}*/}
                {/*{(user.meta && user.meta.britam && user.meta.britam.britam_user_id) &&
                <Button icon="send" content="Verify Account Britam" size="small" color="green" onClick={handleOpenResendVerifyAccountModal}/>}*/}
              </Grid.Column>
            </Grid.Row>
            {(popUp === NotifyCode.PROFILE.SUCCESS || popUp === NotifyCode.PROFILE.ERROR) && <Grid.Row>
              <Grid.Column width={14}>
                <Message success={popUp === NotifyCode.PROFILE.SUCCESS}
                         negative={popUp === NotifyCode.PROFILE.ERROR}>
                  <Message.Header>{popUpMessage}</Message.Header>
                </Message>
              </Grid.Column>
            </Grid.Row>}
            {user.frozen && <Grid.Row>
              <Grid.Column width={14}>
                <Message negative>
                  <Message.Header>Account has be frozen</Message.Header>
                </Message>
              </Grid.Column>
            </Grid.Row>}
            <UserProfile data={user} currentUser={currentUser} showSmileChecks={showSmileChecks} smileChecks={smileChecks} handleShowSmileChecks={handleShowSmileChecks} vendors={vendors}/>
          </Grid>
        </Tab.Pane>
      },
      {
        menuItem: (
            <Menu.Item key='kyc_data'>
              KYC Data<Label
                color={KYCStateColor(user.kyc_state)}
                size="medium"
                content={KYCStateName(user.kyc_state)}/>
            </Menu.Item>
        ), render: () => <Tab.Pane>
          <Grid centered>
            {!!(user.meta && user.meta.kyc_data) ?
                <>
                  <Grid.Row textAlign="right">
                    <Grid.Column width={user.meta.kyc_data.kra_pin || user.meta.kyc_data.verification_requests ? (user.meta.kyc_data.kra_pin || user.meta.kyc_data.id_number) && user.meta.kyc_data.verification_requests ? 14 : 12 : 10} floated="right">
                      { (!!user.meta.kyc_data.verification_requests && user.meta.kyc_data.verification_requests.length > 0) && <Button icon="check square" content="Verification Events" size="small" color="teal" onClick={()=> handleOpenVerificationEventModal(user)}/>}
                      { !!user.meta.kyc_data.kra_pin && <Button content="Check KRA Pin Duplicate" size="small" loading={appState === APP_STATE.LOADING_KRA_PIN_CHECKS} onClick={()=> handleSearchUsersKRA(user)}/>}
                      { !!user.meta.kyc_data.id_number && <Button content="Check ID Number Duplicate" size="small" loading={appState === APP_STATE.LOADING_ID_NUMBER_CHECKS} onClick={()=> handleSearchUsersIDNumber(user)}/>}
                      {/*{ userCan(currentUser.access, userLevels.UPLOAD_USER_KYC_DOCUMENT) && */}
                          <Button icon="user" content="Upload Document" size="small" color="yellow" onClick={()=> handleOpenDocumentModal(user)}/>
                      {/*}*/}
                      {/*{ userCan(currentUser.access, userLevels.UPDATE_USER_KYC_DATA) && */}
                          <Button icon="pencil" content="Update KYC Data" size="small" color="blue" onClick={()=> handleOpenEditModal(user)}/>
                      {/*}*/}
                    </Grid.Column>
                  </Grid.Row>
                  {([NotifyCode.KRA_PIN.SUCCESS,NotifyCode.KRA_PIN.ERROR,NotifyCode.ID_NUMBER.SUCCESS,NotifyCode.ID_NUMBER.ERROR,NotifyCode.UPDATE_KYC.SUCCESS,NotifyCode.UPDATE_KYC.ERROR].includes(popUp)) && <Grid.Row>
                    <Grid.Column width={14}>
                      <Message success={[NotifyCode.KRA_PIN.SUCCESS,NotifyCode.ID_NUMBER.SUCCESS,NotifyCode.UPDATE_KYC.SUCCESS].includes(popUp)} negative={[NotifyCode.KRA_PIN.ERROR,NotifyCode.ID_NUMBER.ERROR,NotifyCode.UPDATE_KYC.ERROR].includes(popUp)}>
                        <Message.Header>{popUpMessage}</Message.Header>
                      </Message>
                    </Grid.Column>
                  </Grid.Row>}
                  <KYCData data={user.meta.kyc_data} user={user} kyc_state={user.kyc_state} currentUser={currentUser} showSmileChecks={showSmileChecks} smileChecks={smileChecks} handleShowSmileChecks={handleShowSmileChecks}/>
                </>
                :
                <Grid.Row>
                  <Grid.Column width={14}>
                    <Header as='h5' content="No KYC records found"/>
                  </Grid.Column>
                </Grid.Row>
            }
          </Grid>
        </Tab.Pane>
      },
      {
        menuItem: (
            <Menu.Item key='verification'>
              Verification
            </Menu.Item>
        ), render: () => <Tab.Pane>
          <Grid centered>
            {!!(user.meta && user.meta.kyc_data) &&
                <>
                  <Grid.Row textAlign="right">
                    <Grid.Column width={user.kyc_state !== 'verified' ? !!user.meta.kyc_data.kra_pin || !!user.meta.kyc_data.kra_pin ? 14 : user.kyc_state !== 'not_provided' ? 12 : 8 : 8} floated="right">
                      { !!user.meta.kyc_data.kra_pin && <Button content="Check KRA Pin Duplicate" size="small" loading={appState === APP_STATE.LOADING_KRA_PIN_CHECKS} onClick={()=> handleSearchUsersKRA(user)}/>}
                      { !!user.meta.kyc_data.id_number && <Button content="Check ID Number Duplicate" size="small" loading={appState === APP_STATE.LOADING_ID_NUMBER_CHECKS} onClick={()=> handleSearchUsersIDNumber(user)}/>}
                      {/*{ userCan(currentUser.access, userLevels.UPDATE_USER_KYC_DATA) && <Button icon="pencil" content="Approve Or Deny KYC" size="small" color="red" onClick={()=> handleOpenEditModal(user)}/>}*/}
                      { (
                          // userCan(currentUser.access, userLevels.UPDATE_USER_KYC_DATA) &&
                          user.kyc_state !== 'verified' && user.kyc_state !== 'not_provided') && <Button.Group size="small" style={{marginRight: 3}}>
                        <Button icon="check circle outline" content="Approve" color="green" disabled={loadIds.includes('approve_kyc')} loading={loadIds.includes('approve_kyc')} onClick={()=> handleOpenEditModal(user,'verified',user.kyc_state === 'auto_verification_passed')}/>
                        <Button.Or />
                        <Button icon="cancel" content="Decline KYC" color="red" disabled={user.kyc_state === 'data_incomplete' || loadIds.includes('approve_kyc')}  onClick={()=> handleOpenEditModal(user,'data_incomplete')}/>
                      </Button.Group>}
                      { (
                          // userCan(currentUser.access, userLevels.UPDATE_USER) &&
                          user.kyc_state !== 'verified') && <Button icon="pencil" content="Update User Details" size="small" color="blue" onClick={()=>handleOpenEditUserModal(user)}/>}
                    </Grid.Column>
                  </Grid.Row>
                  {([NotifyCode.KRA_PIN.SUCCESS,NotifyCode.KRA_PIN.ERROR,NotifyCode.ID_NUMBER.SUCCESS,NotifyCode.ID_NUMBER.ERROR,NotifyCode.UPDATE_KYC.SUCCESS,NotifyCode.UPDATE_KYC.ERROR].includes(popUp)) && <Grid.Row>
                    <Grid.Column width={14}>
                      <Message success={[NotifyCode.KRA_PIN.SUCCESS,NotifyCode.ID_NUMBER.SUCCESS,NotifyCode.UPDATE_KYC.SUCCESS].includes(popUp)} negative={[NotifyCode.KRA_PIN.ERROR,NotifyCode.ID_NUMBER.ERROR,NotifyCode.UPDATE_KYC.ERROR].includes(popUp)}>
                        <Message.Header>{popUpMessage}</Message.Header>
                      </Message>
                    </Grid.Column>
                  </Grid.Row>}
                  <Grid.Row textAlign="right">
                    <Grid.Column
                        width={14} floated="right">
                      { (
                          // userCan(currentUser.access, userLevels.VIEW_SMILE_CHECKS) &&
                              user.meta.smile_id_job && user.meta.smile_id_job.callback_response) &&
                          <Button icon="eye" content={`${showSmileChecks? 'Hide':'Show'} Smile Check`} size="small" color={showSmileChecks? 'yellow':'blue'} onClick={()=>handleShowSmileChecks(user,!showSmileChecks)}/>}
                    </Grid.Column>
                  </Grid.Row>
                </>
            }
            {(user.kyc_state === 'auto_verification_passed' || user.kyc_state === 'needs_human_review') && <Grid.Row>
              <Grid.Column width={12}>
                <Message success={user.kyc_state === 'auto_verification_passed'} negative={user.kyc_state === 'needs_human_review'}>
                  <Message.Header>{user.kyc_state === 'auto_verification_passed' ? 'Auto verification has passed' : 'Needs review'}</Message.Header>
                </Message>
              </Grid.Column>
            </Grid.Row>}
            <Grid.Row>
              <Grid.Column width={14}>
                <Table singleLine striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>#</Table.HeaderCell>
                      <Table.HeaderCell>User KYC</Table.HeaderCell>
                      <Table.HeaderCell>MPESA Info</Table.HeaderCell>
                      <Table.HeaderCell>Smile</Table.HeaderCell>
                      <Table.HeaderCell>Checks</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell><b>Full name</b></Table.Cell>
                      <Table.Cell>{user.fullName}
                        <Popup content='Click to Copy' trigger={
                          <Popup content='Copied to Clipboard' on="click" trigger={
                            <Icon name="copy"
                                  onClick={() => navigator.clipboard.writeText(user.fullName)}/>}
                          />}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {user.meta?.mpesa_info ? user.meta?.mpesa_info?.account_name : '-'} {(user.meta?.mpesa_info && user.meta?.mpesa_info.account_name) && <Popup content='Click to Copy' trigger={
                        <Popup content='Copied to Clipboard' on="click" trigger={
                          <Icon name="copy" onClick={() => navigator.clipboard.writeText(user.meta?.mpesa_info.account_name)}/>}
                        />}
                      />}
                      </Table.Cell>
                      <Table.Cell>
                        {user.meta.smile_id_job && user.meta.smile_id_job.callback_response ? user.meta.smile_id_job.callback_response?.FullName : '-'} {user.meta.smile_id_job && user.meta.smile_id_job.callback_response && <Popup content='Click to Copy' trigger={
                        <Popup content='Copied to Clipboard' on="click" trigger={
                          <Icon name="copy" onClick={() => navigator.clipboard.writeText(user.meta.smile_id_job?.callback_response?.FullName)}/>}
                        />}
                      />}
                      </Table.Cell>
                      <Table.Cell>{showSmileChecks && <>
                        <Label
                            color={smileChecks.mfn.b}
                            basic
                            size="small"
                            content={smileChecks.mfn.m}/>
                      </>}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell><b>Phone number</b></Table.Cell>
                      <Table.Cell>{user.phone_number ?? '-'}
                        <Popup content='Click to Copy' trigger={
                          <Popup content='Copied to Clipboard' on="click" trigger={
                            <Icon name="copy"
                                  onClick={() => navigator.clipboard.writeText(user.phone_number)}/>}
                          />}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {user.meta?.mpesa_info ? user.meta?.mpesa_info?.phone_number : '-'} {(user.meta?.mpesa_info && user.meta?.mpesa_info.phone_number) && <Popup content='Click to Copy' trigger={
                        <Popup content='Copied to Clipboard' on="click" trigger={
                          <Icon name="copy" onClick={() => navigator.clipboard.writeText(user.meta?.mpesa_info.phone_number)}/>}
                        />}
                      />}
                      </Table.Cell>
                      <Table.Cell>-</Table.Cell>
                      <Table.Cell>{' '}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell><b>Date of birth</b></Table.Cell>
                      <Table.Cell>{user.dob? showSmileChecks ? new Date(user.dob).toISOString().split('T')[0] : new Date(user.dob).toDateString(): '-'}
                        <Popup content='Click to Copy' trigger={
                          <Popup content='Copied to Clipboard' on="click" trigger={
                            <Icon name="copy"
                                  onClick={() => navigator.clipboard.writeText(user.dob? new Date(user.dob).toISOString().split('T')[0] : '-')}/>}
                          />}
                        />
                      </Table.Cell>
                      <Table.Cell>-</Table.Cell>
                      <Table.Cell>
                        {user.meta.smile_id_job && user.meta.smile_id_job.callback_response ? user.meta.smile_id_job.callback_response?.DOB : '-'} {user.meta.smile_id_job && user.meta.smile_id_job.callback_response && <Popup content='Click to Copy' trigger={
                        <Popup content='Copied to Clipboard' on="click" trigger={
                          <Icon name="copy" onClick={() => navigator.clipboard.writeText(user.meta.smile_id_job?.callback_response?.DOB)}/>}
                        />}
                      />}
                      </Table.Cell>
                      <Table.Cell>{showSmileChecks && <>
                        <Label
                            color={smileChecks.mdob.b}
                            basic
                            size="small"
                            content={smileChecks.mdob.m}/>
                      </>}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell><b>Kra Pin</b></Table.Cell>
                      <Table.Cell>{user.meta?.kyc_data.kra_pin??'-'}
                        {user.meta?.kyc_data?.kra_pin && <Popup content='Click to Copy' trigger={
                          <Popup content='Copied to Clipboard' on="click" trigger={
                            <Icon name="copy" onClick={() => navigator.clipboard.writeText(user.meta?.kyc_data.kra_pin)}/>}
                          />}
                        />}
                      </Table.Cell>
                      <Table.Cell>-</Table.Cell>
                      <Table.Cell>-</Table.Cell>
                      <Table.Cell>{showSmileChecks && <>
                        <Label
                            color={smileChecks.pin.b}
                            basic
                            size="small"
                            content={smileChecks.pin.m}/>
                      </>}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
            {/*<Grid.Row>
              <Grid.Column width={7}>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader>Full name
                      <Popup content='Click to Copy' trigger={
                        <Popup content='Copied to Clipboard' on="click" trigger={
                          <Icon name="copy"
                                onClick={() => navigator.clipboard.writeText(user.fullName)}/>}
                        />}
                      />
                      {showSmileChecks && <>
                        / Smile Full Name
                        <Popup content='Click to Copy' trigger={
                          <Popup content='Copied to Clipboard' on="click" trigger={
                            <Icon name="copy"
                                  onClick={() => navigator.clipboard.writeText(user.meta.smile_id_job?.callback_response?.FullName)}/>}
                          />}
                        />
                        <Label
                            color={smileChecks.mfn.b}
                            basic
                            size="small"
                            content={smileChecks.mfn.m}/>
                      </>}
                    </Header.Subheader>
                    {user.fullName} {showSmileChecks && <>/ {user.meta.smile_id_job?.callback_response?.FullName}</>}
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={7}>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader>
                      Date of birth
                      <Popup content='Click to Copy' trigger={
                        <Popup content='Copied to Clipboard' on="click" trigger={
                          <Icon name="copy"
                                onClick={() => navigator.clipboard.writeText(user.meta.smile_id_job?.callback_response?.DOB)}/>}
                        />}
                      />
                      {showSmileChecks && <>
                        / Smile Date of birth
                        <Popup content='Click to Copy' trigger={
                          <Popup content='Copied to Clipboard' on="click" trigger={
                            <Icon name="copy"
                                  onClick={() => navigator.clipboard.writeText(user.dob? new Date(user.dob).toISOString().split('T')[0] : '-')}/>}
                          />}
                        />
                        <Label
                            color={smileChecks.mdob.b}
                            basic
                            size="small"
                            content={smileChecks.mdob.m}/>
                      </>}
                    </Header.Subheader>
                    {user.dob? showSmileChecks ? new Date(user.dob).toISOString().split('T')[0] : new Date(user.dob).toDateString(): '-'}
                    {showSmileChecks && <>/ {user.meta.smile_id_job?.callback_response?.DOB}</>}
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={14}>
                <Header as='h3' color="teal" content="MPESA Info" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={7}>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader>
                      Account name {user.meta?.mpesa_info.account_name && <Popup content='Click to Copy' trigger={
                      <Popup content='Copied to Clipboard' on="click" trigger={
                        <Icon name="copy" onClick={() => navigator.clipboard.writeText(user.meta?.mpesa_info.account_name)}/>}
                      />}
                    />}
                    </Header.Subheader>
                    {user.meta?.mpesa_info?.account_name??'-'}
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={7}>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader>
                      Phone number {user.meta?.mpesa_info.phone_number && <Popup content='Click to Copy' trigger={
                      <Popup content='Copied to Clipboard' on="click" trigger={
                        <Icon name="copy" onClick={() => navigator.clipboard.writeText(user.meta?.mpesa_info.phone_number)}/>}
                      />}
                    />}
                    </Header.Subheader>
                    {user.meta?.mpesa_info.phone_number??'-'}
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={14}>
                <Header as='h3' color="teal" content="Smile Info" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={7}>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader>
                      Full name {user.meta.smile_id_job && user.meta.smile_id_job.callback_response && <Popup content='Click to Copy' trigger={
                      <Popup content='Copied to Clipboard' on="click" trigger={
                        <Icon name="copy" onClick={() => navigator.clipboard.writeText(user.meta.smile_id_job?.callback_response?.FullName)}/>}
                      />}
                    />}
                    </Header.Subheader>
                    {user.meta?.smile_id_job?.callback_response?.FullName??'-'}
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={7}>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader>
                      DOB {user.meta.smile_id_job && user.meta.smile_id_job.callback_response && <Popup content='Click to Copy' trigger={
                      <Popup content='Copied to Clipboard' on="click" trigger={
                        <Icon name="copy" onClick={() => navigator.clipboard.writeText(user.meta.smile_id_job?.callback_response?.DOB)}/>}
                      />}
                    />}
                    </Header.Subheader>
                    {user.meta?.smile_id_job?.callback_response?.DOB??'-'}
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={14}>
                <Header as='h3' color="teal" content="KYC Info" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={14}>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader>
                      Kra Pin {user.meta?.kyc_data.kra_pin && <Popup content='Click to Copy' trigger={
                      <Popup content='Copied to Clipboard' on="click" trigger={
                        <Icon name="copy" onClick={() => navigator.clipboard.writeText(user.meta?.kyc_data.kra_pin)}/>}
                      />}
                    />}
                      {showSmileChecks &&
                          <Label
                              color={smileChecks.pin.b}
                              basic
                              size="small"
                              content={smileChecks.pin.m}/>
                      }
                    </Header.Subheader>
                    {user.meta?.kyc_data.kra_pin??'-'}
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>*/}
          </Grid>
        </Tab.Pane>
      },
      {
        menuItem: (
            <Menu.Item key='smile_id'>
              Smile
            </Menu.Item>
        ), render: () => <Tab.Pane>
          <Grid centered>
            {(popUp === NotifyCode.SMILE.SUCCESS || popUp === NotifyCode.SMILE.ERROR) && <Grid.Row>
              <Grid.Column width={14}>
                <Message success={popUp === NotifyCode.SMILE.SUCCESS} negative={popUp === NotifyCode.SMILE.ERROR}>
                  <Message.Header>{popUpMessage}</Message.Header>
                </Message>
              </Grid.Column>
            </Grid.Row>}
            {!!(user.meta &&user.meta.smile_id_job) ?
                <UserSmileData user={user} currentUser={currentUser} handleJobStatus={handleJobStatus} job_status={job_status} handleOpenReEnrollModel={handleOpenReEnrollModel} submitLoader={submitLoader}/>
                :
                <Grid.Row>
                  <Grid.Column width={14}>
                    <Header as='h5' content="No Smile records found"/>
                  </Grid.Column>
                </Grid.Row>
            }
          </Grid>
        </Tab.Pane>
      },
      {
        menuItem: (
            <Menu.Item key='referral'>
              Referral
            </Menu.Item>
        ), render: () => <Tab.Pane>
          <Grid centered>
            <Grid.Row textAlign="right">
              <Grid.Column width={!errorReferrals
              // && (userCan(currentUser.access, userLevels.UPDATE_USER_REFERRAL_DATA) || userCan(currentUser.access, userLevels.CLAIM_USER_REFERRAL))
                  ?9:7} floated="right">
                {/*{ userCan(currentUser.access, userLevels.UPDATE_USER_REFERRAL_DATA) && */}
                    <Button icon="pencil" content='Update Data' size="small" color="blue" onClick={()=>handleOpenEditReferralCodeModal(user)}/>
                {/*}*/}
                {/*{ userCan(currentUser.access, userLevels.CLAIM_USER_REFERRAL) &&  */}
                    <Button icon="hand rock" content='Claim Referral' size="small" color="yellow"
                            disabled={referral_earning === 0 || loadReferrals || errorReferrals} onClick={handleOpenClaimModal}/>
                {/*}*/}
                {!errorReferrals && <Button secondary loading={loadReferrals} disabled={loadReferrals} onClick={()=>refreshReferralData(user.id)} icon="redo" content="Refresh"/>}
              </Grid.Column>
            </Grid.Row>
            {(popUp === NotifyCode.CLAIM.SUCCESS || popUp === NotifyCode.CLAIM.ERROR || popUpList.includes(NotifyCode.FETCH_REFERRALS.ERROR)) && <Grid.Row>
              <Grid.Column width={14}>
                <Message success={popUp === NotifyCode.CLAIM.SUCCESS} negative={popUp === NotifyCode.CLAIM.ERROR || NotifyCode.FETCH_REFERRALS.ERROR}>
                  <Message.Header>{NotifyCode.FETCH_REFERRALS.ERROR ? popUpMessageList[NotifyCode.FETCH_REFERRALS.ERROR]:popUpMessage}</Message.Header>
                </Message>
              </Grid.Column>
            </Grid.Row>}
            <Grid.Row>
              <Grid.Column width={5}>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader>Referral Code</Header.Subheader>
                    {user.referral_code}
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={5}>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader>Can refer others</Header.Subheader>
                    <Label
                        color={user.meta && user.meta.referral_info && user.meta.referral_info.can_refer_others?'green':'red'}
                        size="medium" content={user.meta && user.meta.referral_info && user.meta.referral_info.can_refer_others?'True' : 'False'} />
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4}>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader>Redemption Mode</Header.Subheader>
                    {user.meta && user.meta.referral_info? user.meta.referral_info.redemption_mode : ''}
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader>Max Number of Referrals</Header.Subheader>
                    {user.meta && user.meta.referral_info? user.meta.referral_info.number_of_referrals: ''}
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={5}>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader>Earning Per Referral</Header.Subheader>
                    {toReadableValue(user.meta && user.meta.referral_info? user.meta.referral_info.earning_per_referral : 0,
                        user.meta && user.meta.referral_info? user.meta.referral_info.referral_currency : '')}{' '}
                    {currencyCode(user.meta && user.meta.referral_info? user.meta.referral_info.referral_currency : '')}
                  </Header.Content>
                </Header>
              </Grid.Column>
              <Grid.Column width={4}>
                <Header as='h4'>
                  <Header.Content>
                    <Header.Subheader>Referral Currency</Header.Subheader>
                    {currencyCode(user.meta && user.meta.referral_info? user.meta.referral_info.referral_currency : '')}
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Divider/>
            {!loadReferrals ?
                !errorReferrals ?
                    referrals.length > 0 ?
                        <>
                          <Grid.Row>
                            <Grid.Column width={5}>
                              <Header as='h4'>
                                <Header.Content>
                                  <Header.Subheader>Total Referrals</Header.Subheader>
                                  {referrals.length}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={5}>
                              <Header as='h4'>
                                <Header.Content>
                                  <Header.Subheader>Total Earnings</Header.Subheader>
                                  {toReadableValue(referral_earning)} {currencyCode()}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Header as='h4'>
                                <Header.Content>
                                  <Header.Subheader>Total Deposit</Header.Subheader>
                                  {toReadableValue(referral_deposits)} {currencyCode()}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={14}>
                              <div style={{display: 'flex', width: '50%'}}>
                                <Input label="Filter" placeholder="Search" value={searchTableReferrals}
                                       onChange={(e) => setSearchTableReferrals(e.target.value)}
                                       style={{width: '100%', marginRight: 3}}
                                />
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={14}>
                              <Table singleLine basic='very' striped>
                                <Table.Header>
                                  <Table.Row>
                                    <Table.HeaderCell>Name</Table.HeaderCell>
                                    <Table.HeaderCell>Deposit</Table.HeaderCell>
                                    <Table.HeaderCell>Earning</Table.HeaderCell>
                                    <Table.HeaderCell>State</Table.HeaderCell>
                                    <Table.HeaderCell>Date</Table.HeaderCell>
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  {referrals.filter(item=>searchOnTable(searchTableReferrals,
                                      [item.user.id,item.user.first_name,item.user.last_name,`${item.user.first_name} ${item.user.last_name}`,toReadableValue(item.deposits,item.currency_code),
                                        toReadableValue(item.earning,item.currency_code),item.state,toReadableDateTime(item.created_at),item.created_at])).map((item,i) =>
                                      <Table.Row key={i}>
                                        <Table.Cell>{`${item.user.first_name} ${item.user.last_name} `}
                                          <Popup content='Open profile' trigger={
                                          <Icon name="external alternate" onClick={()=>viewUser(item.user.id)}/>}
                                        /></Table.Cell>
                                        <Table.Cell>{toReadableValue(item.deposits,item.currency_code)} {currencyCode(item.currency_code)}</Table.Cell>
                                        <Table.Cell>{toReadableValue(item.earning,item.currency_code)} {currencyCode(item.currency_code)}</Table.Cell>
                                        <Table.Cell>{item.state}</Table.Cell>
                                        <Table.Cell>{toReadableDateTime(item.created_at)}</Table.Cell>
                                      </Table.Row>
                                  )
                                  }
                                </Table.Body>
                              </Table>
                            </Grid.Column>
                          </Grid.Row>
                        </>
                        :
                        <Grid.Row>
                          <Grid.Column width={3}>
                            <p>No referrals</p>
                          </Grid.Column>
                        </Grid.Row>
                    :
                    <Grid.Row>
                      <Grid.Column width={5}>
                        <p>Ops! Something went wrong</p>
                        <Button secondary onClick={()=>refreshReferralData(user.id)} icon="redo" content="Reload" size="tiny"/>
                      </Grid.Column>
                    </Grid.Row>
                :
                <Grid.Row>
                  <Grid.Column width={1}>
                    <Loader active inline>Loading</Loader>
                  </Grid.Column>
                </Grid.Row>
            }
          </Grid>
        </Tab.Pane>
      },
    ]
    // if(userCan(currentUser.access, userLevels.VIEW_USER_GOALS)){
    panes = [...panes, {
      menuItem: (
          <Menu.Item key='goals'>
            Goals
          </Menu.Item>
      ), render: () => <Tab.Pane>
        <Grid centered>
          <Grid.Row textAlign="right">
            <Grid.Column width={goals
                // && userCan(currentUser.access, userLevels.RECON_USER_TRANSACTION)
                ? 10 : 3} floated="right">
              {(goals && goals.length > 0
                  // && userCan(currentUser.access, userLevels.RECON_USER_TRANSACTION)
              ) && <>
                <Menu compact style={{marginRight: 5, height: '40px', maxHeight: '40px', minHeight: '40px'}}>
                  <Dropdown text="Show/Hide" pointing className='link item' simple scrolling>
                    <Dropdown.Menu>
                      <Dropdown.Header>
                        Vendors <Button size="tiny" onClick={()=> setAppShowHideAll(vendors ? vendors.filter(e=>e.enabled).map(item=>item.id) : [],vendors && vendors.filter(e=>e.enabled).length === vendors.filter(e=>e.enabled).filter(e=> !showHide.includes(e.id)).length)}>
                        {vendors && vendors.filter(e=>e.enabled).length === vendors.filter(e=>e.enabled).filter(e=> !showHide.includes(e.id)).length ? `Unselect all`:`Select all`}</Button></Dropdown.Header>
                      {
                          (vendors && vendors.filter(e=>e.enabled).length > 0) &&
                          vendors.filter(e=>e.enabled).map(item=>
                              <Dropdown.Item key={item.id} onClick={()=> setAppShowHide(item.id)}>
                                {!showHide.includes(item.id) ? <Icon name="check square outline"/> : <Icon name="square outline"/>} {item.name}
                              </Dropdown.Item>)
                      }
                      <Dropdown.Divider />
                      <Dropdown.Header>Goals</Dropdown.Header>
                      <Dropdown.Item onClick={()=> setAppShowHide(showHideKeys.LOCK_GOAL)}>
                        {!showHide.includes(showHideKeys.LOCK_GOAL) ? <Icon name="check square outline"/> : <Icon name="square outline"/>} Lock
                      </Dropdown.Item>
                      <Dropdown.Item onClick={()=> setAppShowHide(showHideKeys.NON_LOCK_GOAL)}>
                        {!showHide.includes(showHideKeys.NON_LOCK_GOAL) ? <Icon name="check square outline"/> : <Icon name="square outline"/>} Non-lock
                      </Dropdown.Item>
                      <Dropdown.Item onClick={()=> setAppShowHide(showHideKeys.DELETE_GOAL)}>
                        {!showHide.includes(showHideKeys.DELETE_GOAL) ? <Icon name="check square outline"/> : <Icon name="square outline"/>} Deleted
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Header>Product <Button size="tiny" onClick={()=> setAppShowHideAll(products ? products.map(item=>item.id) : [],products && products.length === products.filter(e=> !showHide.includes(e.id)).length)}>
                        {products && products.length === products.filter(e=> !showHide.includes(e.id)).length ? `Unselect all`:`Select all`}</Button></Dropdown.Header>
                      {
                          (products && products.length > 0) &&
                          products.map(item=>
                              <Dropdown.Item key={item.id} onClick={()=> setAppShowHide(item.id)}>
                                {!showHide.includes(item.id) ? <Icon name="check square outline"/> : <Icon name="square outline"/>} {item.name}
                              </Dropdown.Item>)
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu>
                <Divider hidden vertical/>
                <Button icon="share" content="Reconcile User's Transactions" color="blue"
                           onClick={handleOpenReconcileTransactionModal}/></>}
              <Button secondary loading={loadGoals} disabled={loadGoals} onClick={() => handleUserGoals(user)}
                      icon="redo" content="Refresh"/>
            </Grid.Column>
          </Grid.Row>
          {((popUp === NotifyCode.GOAL.SUCCESS || popUp === NotifyCode.GOAL.ERROR || popUpList.includes(NotifyCode.FETCH_GOALS.ERROR))) &&
              <Grid.Row>
                <Grid.Column width={14}>
                  <Message success={popUp === NotifyCode.GOAL.SUCCESS}
                           negative={popUp === NotifyCode.GOAL.ERROR || popUpList.includes(NotifyCode.FETCH_GOALS.ERROR)}>
                    <Message.Header>{NotifyCode.FETCH_GOALS.ERROR ? popUpMessageList[NotifyCode.FETCH_GOALS.ERROR] : popUpMessage}</Message.Header>
                  </Message>
                </Grid.Column>
              </Grid.Row>}
        </Grid>
        {!loadGoals ?
            !errorGoals
                ? (filterShowHideGoals(goals,[]).length ?
                    <>
                      <Grid centered>
                        <Grid.Row>
                          <Grid.Column width={4}>
                            <Header as='h4'>
                              <Header.Content>
                                <Header.Subheader>Total Deposits</Header.Subheader>
                                {toReadableValue(getTotalDeposit())} {currencyCode()}
                              </Header.Content>
                            </Header>
                          </Grid.Column>
                          <Grid.Column width={4}>
                            <Header as='h4'>
                              <Header.Content>
                                <Header.Subheader>Total Withdrawable</Header.Subheader>
                                {toReadableValue(getTotalWithdrawable())} {currencyCode()}
                              </Header.Content>
                            </Header>
                          </Grid.Column>
                          <Grid.Column width={4}>
                            <Header as='h5'>
                              <Header.Content>
                                <Header.Subheader>Daily Interest <Icon name="chevron up" color="blue"/></Header.Subheader>
                                {toReadableValue(getAllDailyInterest())} {currencyCode()}
                              </Header.Content>
                            </Header>
                          </Grid.Column>
                          {/*<Grid.Column width={5}>
                        <Header as='h4'>
                          <Header.Content>
                            <Header.Subheader>Total Interest</Header.Subheader>
                            {toReadableValue(getTotalInterest())} {currencyCode()}
                          </Header.Content>
                        </Header>
                      </Grid.Column>*/}
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={3}>
                            <Header as='h5'>
                              <Header.Content>
                                <Header.Subheader>All Deposits <Icon name="level up alternate"
                                                                     color="green"/></Header.Subheader>
                                {toReadableValue(getAllDeposit())} {currencyCode()}
                              </Header.Content>
                            </Header>
                          </Grid.Column>
                          <Grid.Column width={3}>
                            <Header as='h5'>
                              <Header.Content>
                                <Header.Subheader>All Withdrawals <Icon name="level down alternate"
                                                                        color="red"/></Header.Subheader>
                                {toReadableValue(getAllWithdrawals())} {currencyCode()}
                              </Header.Content>
                            </Header>
                          </Grid.Column>
                          <Grid.Column width={3}>
                            <Header as='h5'>
                              <Header.Content>
                                <Header.Subheader>All Withdrawal Charges <Icon name="level down alternate"
                                                                        color="red"/></Header.Subheader>
                                {toReadableValue(getAllWithdrawalCharges())} {currencyCode()}
                              </Header.Content>
                            </Header>
                          </Grid.Column>
                          <Grid.Column width={3}>
                            <Header as='h5'>
                              <Header.Content>
                                <Header.Subheader>All Interest <Icon name="chevron up" color="blue"/></Header.Subheader>
                                {toReadableValue(getAllInterest())} {currencyCode()}
                              </Header.Content>
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      {
                          (vendors && vendors.filter(e=>e.enabled).length > 0) &&<>
                            <Divider hidden/>
                            <Grid centered>
                              <Grid.Row>
                                <Grid.Column width={14}>
                                  <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Header as='h3' content={`Vendor Balances`}/>
                                  </div>
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row>
                                <Grid.Column width={14}>
                                  <Accordion fluid styled>
                                    {vendors.filter(e=>e.enabled && !showHide.includes(e.id)).map(item=><React.Fragment key={item.id}>
                                      <Accordion.Title
                                          active={activeGoalBalanceIndex.includes(item.id)}
                                          index={item.id}
                                          onClick={handleActiveBalanceGoal}
                                      >
                                        {item.name}
                                        <Icon name='dropdown'/>
                                      </Accordion.Title>
                                      <Accordion.Content active={activeGoalBalanceIndex.includes(item.id)}>
                                        <Grid centered>
                                          <Grid.Row>
                                            <Grid.Column width={4}>
                                              <Header as='h4'>
                                                <Header.Content>
                                                  <Header.Subheader>Total Deposits</Header.Subheader>
                                                  {toReadableValue(getTotalDeposit(item.id))} {currencyCode()}
                                                </Header.Content>
                                              </Header>
                                            </Grid.Column>
                                            <Grid.Column width={4}>
                                              <Header as='h4'>
                                                <Header.Content>
                                                  <Header.Subheader>Total Withdrawable</Header.Subheader>
                                                  {toReadableValue(getTotalWithdrawable(item.id))} {currencyCode()}
                                                </Header.Content>
                                              </Header>
                                            </Grid.Column>
                                            <Grid.Column width={4}>
                                              <Header as='h5'>
                                                <Header.Content>
                                                  <Header.Subheader>Daily Interest <Icon name="chevron up" color="blue"/></Header.Subheader>
                                                  {toReadableValue(getAllDailyInterest(item.id))} {currencyCode()}
                                                </Header.Content>
                                              </Header>
                                            </Grid.Column>
                                          </Grid.Row>
                                          <Grid.Row>
                                            <Grid.Column width={3}>
                                              <Header as='h5'>
                                                <Header.Content>
                                                  <Header.Subheader>All Deposits <Icon name="level up alternate"
                                                                                       color="green"/></Header.Subheader>
                                                  {toReadableValue(getAllDeposit(item.id))} {currencyCode()}
                                                </Header.Content>
                                              </Header>
                                            </Grid.Column>
                                            <Grid.Column width={4}>
                                              <Header as='h5'>
                                                <Header.Content>
                                                  <Header.Subheader>All Withdrawals <Icon name="level down alternate"
                                                                                          color="red"/></Header.Subheader>
                                                  {toReadableValue(getAllWithdrawals(item.id))} {currencyCode()}
                                                </Header.Content>
                                              </Header>
                                            </Grid.Column>
                                            <Grid.Column width={4}>
                                              <Header as='h5'>
                                                <Header.Content>
                                                  <Header.Subheader>All Withdrawal Charges <Icon name="level down alternate"
                                                                                                 color="red"/></Header.Subheader>
                                                  {toReadableValue(getAllWithdrawalCharges(item.id))} {currencyCode()}
                                                </Header.Content>
                                              </Header>
                                            </Grid.Column>
                                            <Grid.Column width={3}>
                                              <Header as='h5'>
                                                <Header.Content>
                                                  <Header.Subheader>All Interest <Icon name="chevron up" color="blue"/></Header.Subheader>
                                                  {toReadableValue(getAllInterest(item.id))} {currencyCode()}
                                                </Header.Content>
                                              </Header>
                                            </Grid.Column>
                                          </Grid.Row>
                                        </Grid>
                                      </Accordion.Content>
                                    </React.Fragment>)}
                                  </Accordion>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                            <Divider hidden/>
                          </>
                      }
                      <Grid centered>
                        <Grid.Row>
                          <Grid.Column width={14}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <Header as='h3' content={`Transactions`}/>
                            </div>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={14}>
                            <Accordion fluid styled>
                              <Accordion.Title
                                  active={activeGoalBalanceIndex.includes('p_t')}
                                  index={'p_t'}
                                  onClick={handleActiveBalanceGoal}
                              >
                                Pending transactions ({getAllGoalTransactionsWithStatus().length})
                                <Icon name='dropdown'/>
                              </Accordion.Title>
                              <Accordion.Content active={activeGoalBalanceIndex.includes('p_t')}>
                                {sortItemsByDate(getAllGoalTransactionsWithStatus()).map((e,ii)=>
                                    <GoalTransactions key={e.id} e={e} ii={ii} item={null} activeGoalTransactionIndex={activeGoalTransactionIndex}
                                                      handleActiveGoalTransaction={handleActiveGoalTransaction} goalTransactionState={goalTransactionState}
                                                      goalTransactionStateOpen={goalTransactionStateOpen} goalTransactionStateClose={goalTransactionStateClose}
                                                      handleSetGoalTransactionState={handleSetGoalTransactionState} viewGoalsTransaction={viewGoalsTransaction} transactions={sortItemsByDate(getAllGoalTransactionsWithStatus())}/>
                                )}
                              </Accordion.Content>
                              <Accordion.Title
                                  active={activeGoalBalanceIndex.includes('f_t')}
                                  index={'f_t'}
                                  onClick={handleActiveBalanceGoal}
                              >
                                Failed transactions ({getAllGoalTransactionsWithStatus('failure').length})
                                <Icon name='dropdown'/>
                              </Accordion.Title>
                              <Accordion.Content active={activeGoalBalanceIndex.includes('f_t')}>
                                {sortItemsByDate(getAllGoalTransactionsWithStatus('failure')).map((e,ii)=>
                                    <GoalTransactions key={e.id} e={e} ii={ii} item={null} activeGoalTransactionIndex={activeGoalTransactionIndex}
                                                      handleActiveGoalTransaction={handleActiveGoalTransaction} goalTransactionState={goalTransactionState}
                                                      goalTransactionStateOpen={goalTransactionStateOpen} goalTransactionStateClose={goalTransactionStateClose}
                                                      handleSetGoalTransactionState={handleSetGoalTransactionState} viewGoalsTransaction={viewGoalsTransaction} transactions={sortItemsByDate(getAllGoalTransactionsWithStatus('failure'))}/>
                                )}
                              </Accordion.Content>
                            </Accordion>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      <Divider hidden/>

                      <Tab panes={userGoalPanes(filterShowHideGoals(goals,showHide))} style={{overflow: 'auto hidden'}}/>
                    </>
                    :
                    <Container text textAlign="center" content="No records found"/>)
                :
                <Grid centered>
                  <Grid.Row textAlign="right">
                    <Grid.Column width={5}>
                      <p>Ops! Something went wrong</p>
                      <Button secondary onClick={() => handleUserGoals(user)} icon="redo" content="Reload" size="tiny"/>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
            :
            <Grid centered>
              <Grid.Row textAlign="right">
                <Grid.Column width={1}>
                  <Loader active inline>Loading</Loader>
                </Grid.Column>
              </Grid.Row>
            </Grid>}
      </Tab.Pane>
    }]
    return panes
  }

  const goalTransactionsPanes = (item) =>{
    return [
      {
        menuItem:<Menu.Item key='all'>
          All
        </Menu.Item>, render: () => <Tab.Pane>
          {sortItemsByDate(item.transactions).map((e,ii)=>
              <GoalTransactions key={e.id} e={e} ii={ii} item={item} activeGoalTransactionIndex={activeGoalTransactionIndex}
                                handleActiveGoalTransaction={handleActiveGoalTransaction} goalTransactionState={goalTransactionState}
                                goalTransactionStateOpen={goalTransactionStateOpen} goalTransactionStateClose={goalTransactionStateClose}
                                handleSetGoalTransactionState={handleSetGoalTransactionState} viewGoalsTransaction={viewGoalsTransaction} transactions={sortItemsByDate(item.transactions)}/>
          )}
        </Tab.Pane>
      },
      {
        menuItem: (
            <Menu.Item key='deposits'>
              Deposits <Label color="green" size="small" content={filterTransactionsToTabs(item.transactions,'inbound').length} />
            </Menu.Item>
        ), render: () => <Tab.Pane>
          {sortItemsByDate(filterTransactionsToTabs(item.transactions,'inbound')).map((e,ii)=>
              <GoalTransactions key={e.id} e={e} ii={ii} item={item} activeGoalTransactionIndex={activeGoalTransactionIndex}
                                handleActiveGoalTransaction={handleActiveGoalTransaction} goalTransactionState={goalTransactionState}
                                goalTransactionStateOpen={goalTransactionStateOpen} goalTransactionStateClose={goalTransactionStateClose}
                                handleSetGoalTransactionState={handleSetGoalTransactionState} viewGoalsTransaction={viewGoalsTransaction} transactions={sortItemsByDate(filterTransactionsToTabs(item.transactions,'inbound'))}/>
          )}
        </Tab.Pane>
      },
      {
        menuItem: (
            <Menu.Item key='withdrawals'>
              Withdrawals <Label color="red" size="small" content={filterTransactionsToTabs(item.transactions,'outbound').length} />
            </Menu.Item>
        ), render: () => <Tab.Pane>
          {sortItemsByDate(filterTransactionsToTabs(item.transactions,'outbound')).map((e,ii)=>
              <GoalTransactions key={e.id} e={e} ii={ii} item={item} activeGoalTransactionIndex={activeGoalTransactionIndex}
                                handleActiveGoalTransaction={handleActiveGoalTransaction} goalTransactionState={goalTransactionState}
                                goalTransactionStateOpen={goalTransactionStateOpen} goalTransactionStateClose={goalTransactionStateClose}
                                handleSetGoalTransactionState={handleSetGoalTransactionState} viewGoalsTransaction={viewGoalsTransaction} transactions={sortItemsByDate(filterTransactionsToTabs(item.transactions,'outbound'))}/>
          )}
        </Tab.Pane>
      },
      {
        menuItem: (
            <Menu.Item key='interests'>
              Interest <Label color="blue" size="small" content={filterTransactionsToTabs(item.transactions,'interest').length} />
            </Menu.Item>
        ), render: () => <Tab.Pane>
          {sortItemsByDate(filterTransactionsToTabs(item.transactions,'interest')).map((e,ii)=>
              <GoalTransactions key={e.id} e={e} ii={ii} item={item} activeGoalTransactionIndex={activeGoalTransactionIndex}
                                handleActiveGoalTransaction={handleActiveGoalTransaction} goalTransactionState={goalTransactionState}
                                goalTransactionStateOpen={goalTransactionStateOpen} goalTransactionStateClose={goalTransactionStateClose}
                                handleSetGoalTransactionState={handleSetGoalTransactionState} viewGoalsTransaction={viewGoalsTransaction} transactions={sortItemsByDate(filterTransactionsToTabs(item.transactions,'interest'))}/>
          )}
        </Tab.Pane>
      },
      {
        menuItem: (
            <Menu.Item key='successful'>
              Successful <Label color="green" size="small" content={filterTransactionsToTabs(item.transactions,'success').length} />
            </Menu.Item>
        ), render: () => <Tab.Pane>
          {sortItemsByDate(filterTransactionsToTabs(item.transactions,'success')).map((e,ii)=>
              <GoalTransactions key={e.id} e={e} ii={ii} item={item} activeGoalTransactionIndex={activeGoalTransactionIndex}
                                handleActiveGoalTransaction={handleActiveGoalTransaction} goalTransactionState={goalTransactionState}
                                goalTransactionStateOpen={goalTransactionStateOpen} goalTransactionStateClose={goalTransactionStateClose}
                                handleSetGoalTransactionState={handleSetGoalTransactionState} viewGoalsTransaction={viewGoalsTransaction} transactions={sortItemsByDate(filterTransactionsToTabs(item.transactions,'success'))}/>
          )}
        </Tab.Pane>
      },
      {
        menuItem: (
            <Menu.Item key='pending'>
              Pending <Label color="yellow" size="small" content={filterTransactionsToTabs(item.transactions,'pending').length} />
            </Menu.Item>
        ), render: () => <Tab.Pane>
          {sortItemsByDate(filterTransactionsToTabs(item.transactions,'pending')).map((e,ii)=>
              <GoalTransactions key={e.id} e={e} ii={ii} item={item} activeGoalTransactionIndex={activeGoalTransactionIndex}
                                handleActiveGoalTransaction={handleActiveGoalTransaction} goalTransactionState={goalTransactionState}
                                goalTransactionStateOpen={goalTransactionStateOpen} goalTransactionStateClose={goalTransactionStateClose}
                                handleSetGoalTransactionState={handleSetGoalTransactionState} viewGoalsTransaction={viewGoalsTransaction} transactions={sortItemsByDate(filterTransactionsToTabs(item.transactions,'pending'))}/>
          )}
        </Tab.Pane>
      },
      {
        menuItem: (
            <Menu.Item key='failed'>
              Failed <Label color="red" size="small" content={filterTransactionsToTabs(item.transactions,'failure').length} />
            </Menu.Item>
        ), render: () => <Tab.Pane>
          {sortItemsByDate(filterTransactionsToTabs(item.transactions,'failure')).map((e,ii)=>
              <GoalTransactions key={e.id} e={e} ii={ii} item={item} activeGoalTransactionIndex={activeGoalTransactionIndex}
                                handleActiveGoalTransaction={handleActiveGoalTransaction} goalTransactionState={goalTransactionState}
                                goalTransactionStateOpen={goalTransactionStateOpen} goalTransactionStateClose={goalTransactionStateClose}
                                handleSetGoalTransactionState={handleSetGoalTransactionState} viewGoalsTransaction={viewGoalsTransaction} transactions={sortItemsByDate(filterTransactionsToTabs(item.transactions,'failure'))}/>
          )}
        </Tab.Pane>
      },
      {
        menuItem: (
            <Menu.Item key='open'>
              Open <Label color="grey" size="small" content={filterTransactionsToTabs(item.transactions,'open').length} />
            </Menu.Item>
        ), render: () => <Tab.Pane>
          {sortItemsByDate(filterTransactionsToTabs(item.transactions,'open')).map((e,ii)=>
              <GoalTransactions key={e.id} e={e} ii={ii} item={item} activeGoalTransactionIndex={activeGoalTransactionIndex}
                                handleActiveGoalTransaction={handleActiveGoalTransaction} goalTransactionState={goalTransactionState}
                                goalTransactionStateOpen={goalTransactionStateOpen} goalTransactionStateClose={goalTransactionStateClose}
                                handleSetGoalTransactionState={handleSetGoalTransactionState} viewGoalsTransaction={viewGoalsTransaction} transactions={sortItemsByDate(filterTransactionsToTabs(item.transactions,'open'))}/>
          )}
        </Tab.Pane>
      },
      {
        menuItem: (
            <Menu.Item key='closed'>
              Closed <Label color="black" size="small" content={filterTransactionsToTabs(item.transactions,'closed').length} />
            </Menu.Item>
        ), render: () => <Tab.Pane>
          {sortItemsByDate(filterTransactionsToTabs(item.transactions,'closed')).map((e,ii)=>
              <GoalTransactions key={e.id} e={e} ii={ii} item={item} activeGoalTransactionIndex={activeGoalTransactionIndex}
                                handleActiveGoalTransaction={handleActiveGoalTransaction} goalTransactionState={goalTransactionState}
                                goalTransactionStateOpen={goalTransactionStateOpen} goalTransactionStateClose={goalTransactionStateClose}
                                handleSetGoalTransactionState={handleSetGoalTransactionState} viewGoalsTransaction={viewGoalsTransaction} transactions={sortItemsByDate(filterTransactionsToTabs(item.transactions,'closed'))}/>
          )}
        </Tab.Pane>
      },
    ]
  }

  const userGoalPanes = (value) =>{
    const panes = []
    const productsPanes = []
    const defaultPanes = [
      {key:'all',name:'All'},
      {key:'other',name:'Other'}
    ]
    defaultPanes.forEach(e=> {
      const key = e.key
      const goals = value.filter(goal=> key === 'all' || (goal.product_id === null && key === 'other') )
      const transactions = []
      goals.filter(e=>e.transactions && e.transactions.length > 0).forEach(e=>
          transactions.push(...e.transactions)
      )
      return panes.push({
        menuItem:<Menu.Item key={key}>
          {e.name} ({goals.length})
        </Menu.Item>, render: () => <Tab.Pane>
          {/*<Grid centered>
            <Grid.Row>
              <Grid.Column width={14}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Header as='h3' content={`Goal(s) (${goals.length})`}/>
                  {goals.length > 1 && <Button content={expandAllGoals ? "Collapse All" : "Expand All"} toggle
                                               style={{marginLeft: 5,marginTop:-20}}
                                               active={expandAllGoals}
                                               onClick={() => handleExpandAllGoals(goals,!expandAllGoals,`${key}-`)}/>}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>*/}
          {goals.length === 0 ? <>No goals available</>:
            <>
              <Grid centered>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <Header as='h5'>
                      <Header.Content>
                        <Header.Subheader>Total Product Goal Deposits</Header.Subheader>
                        {toReadableValue(getTotalGoalTransactionDeposit(transactions ?? []), goals[0].currency_code)} {currencyCode(goals[0].currency_code)}
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Header as='h5'>
                      <Header.Content>
                        <Header.Subheader>Total Product Goal Withdrawable</Header.Subheader>
                        {toReadableValue(getTotalGoalTransactionWithdrawable(transactions ?? []), goals[0].currency_code)} {currencyCode(goals[0].currency_code)}
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Header as='h6'>
                      <Header.Content>
                        <Header.Subheader>Daily Product Goal Interest <Icon name="chevron up"
                                                                            color="blue"/></Header.Subheader>
                        {toReadableValue(getAllGoalTransactionInterest(getItemsByDateRange(transactions?? [])), goals[0].currency_code)} {currencyCode(goals[0].currency_code)}
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as='h6'>
                      <Header.Content>
                        <Header.Subheader>All Product Goal Deposits <Icon name="level up alternate"
                                                                          color="green"/></Header.Subheader>
                        {toReadableValue(getAllGoalTransactionDeposit(transactions ?? 0), goals[0].currency_code)} {currencyCode(goals[0].currency_code)}
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Header as='h6'>
                      <Header.Content>
                        <Header.Subheader>All Product Goal Withdrawals <Icon name="level down alternate"
                                                                             color="red"/></Header.Subheader>
                        {toReadableValue(getAllGoalTransactionWithdrawals(transactions ?? 0), goals[0].currency_code)} {currencyCode(goals[0].currency_code)}
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Header as='h6'>
                      <Header.Content>
                        <Header.Subheader>All Product Goal Withdrawal Charges <Icon name="level down alternate"
                                                                                    color="red"/></Header.Subheader>
                        {toReadableValue(getAllGoalTransactionWithdrawalCharges(transactions ?? 0), goals[0].currency_code)} {currencyCode(goals[0].currency_code)}
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Header as='h6'>
                      <Header.Content>
                        <Header.Subheader>All Product Goal Interest <Icon name="chevron up"
                                                                          color="blue"/></Header.Subheader>
                        {toReadableValue(getAllGoalTransactionInterest(transactions ?? 0), goals[0].currency_code)} {currencyCode(goals[0].currency_code)}
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Divider hidden/>
              <Accordion fluid styled>
                {goals.map((item, i) =>
                    <React.Fragment key={item.id}>
                      <Accordion.Title
                          active={activeGoalIndex.includes(`${key}-${i}`)}
                          index={`${key}-${i}`}
                          onClick={handleActiveGoal}
                      >
                        <Icon name='dropdown'/>
                        {item.name}
                        <small>(Transactions({item.transactions ? item.transactions.length : 0}))</small>
                        {!!item.deleted_at && <>{' '} <Label color="red" content="Deleted"/></>}
                        {' '} | Balance <Label basic color="blue" content={`${toReadableValue(getTotalGoalTransactionDeposit(item.transactions ?? 0), item.currency_code)} ${currencyCode(item.currency_code)}`}/>
                        {' '} | Daily Interest <Label basic color="green" content={`${toReadableValue(getAllGoalTransactionInterest(getItemsByDateRange(item.transactions?? [])), item.currency_code)} ${currencyCode(item.currency_code)}`}/>
                        {item.target > 0 && <>
                          {' '} | Target <Label basic color={goalTargetPercentage(getTotalGoalTransactionDeposit(item.transactions ?? 0),item.target) >= 80 ? "green" : goalTargetPercentage(getTotalGoalTransactionDeposit(item.transactions ?? 0),item.target) >= 50 ? "yellow":"red"} content={`${goalTargetPercentage(getTotalGoalTransactionDeposit(item.transactions ?? 0),item.target)}%`}/>
                        </>}
                        {item.locked && <>{' | '} <Icon name="lock" color="orange"/></>}
                      </Accordion.Title>
                      <Accordion.Content active={activeGoalIndex.includes(`${key}-${i}`)}>
                        <Image
                            floated='right'
                            size='small'
                            src={item.img_url}
                        />
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={14}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>ID</Header.Subheader>
                                  {item.id}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={5}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Total Goal Deposits</Header.Subheader>
                                  {toReadableValue(getTotalGoalTransactionDeposit(item.transactions ?? []), item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={5}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Total Goal Withdrawable</Header.Subheader>
                                  {toReadableValue(getTotalGoalTransactionWithdrawable(item.transactions ?? []), item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Header as='h6'>
                                <Header.Content>
                                  <Header.Subheader>Daily Goal Interest <Icon name="chevron up"
                                                                              color="blue"/></Header.Subheader>
                                  {toReadableValue(getAllGoalTransactionInterest(getItemsByDateRange(item.transactions?? [])), item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            {/*<Grid.Column width={4}>
                                  <Header as='h5'>
                                    <Header.Content>
                                      <Header.Subheader>Total Goal Interest</Header.Subheader>
                                      {toReadableValue(getTotalGoalTransactionInterest(item.transactions ?? 0),item.currency_code)} {currencyCode(item.currency_code)}
                                    </Header.Content>
                                  </Header>
                                </Grid.Column>*/}
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={4}>
                              <Header as='h6'>
                                <Header.Content>
                                  <Header.Subheader>All Goal Deposits <Icon name="level up alternate"
                                                                            color="green"/></Header.Subheader>
                                  {toReadableValue(getAllGoalTransactionDeposit(item.transactions ?? 0), item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Header as='h6'>
                                <Header.Content>
                                  <Header.Subheader>All Goal Withdrawals <Icon name="level down alternate"
                                                                               color="red"/></Header.Subheader>
                                  {toReadableValue(getAllGoalTransactionWithdrawals(item.transactions ?? 0), item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Header as='h6'>
                                <Header.Content>
                                  <Header.Subheader>All Goal Withdrawal Charges <Icon name="level down alternate"
                                                                                      color="red"/></Header.Subheader>
                                  {toReadableValue(getAllGoalTransactionWithdrawalCharges(item.transactions ?? 0), item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Header as='h6'>
                                <Header.Content>
                                  <Header.Subheader>All Goal Interest <Icon name="chevron up"
                                                                            color="blue"/></Header.Subheader>
                                  {toReadableValue(getAllGoalTransactionInterest(item.transactions ?? 0), item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={7}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Deposits ({item.recurrence})</Header.Subheader>
                                  {toReadableValue(item.deposits, item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={7}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Target</Header.Subheader>
                                  {toReadableValue(item.target, item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={4}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Recurrence</Header.Subheader>
                                  {item.recurrence}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Enabled</Header.Subheader>
                                  <Label
                                      color={item.enabled ? 'green' : 'red'}
                                      size="medium" content={item.enabled ? 'True' : 'False'}/>
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Locked</Header.Subheader>
                                  <Label
                                      color={item.locked ? 'green' : 'red'}
                                      size="medium" content={item.locked ? 'True' : 'False'}/>
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={14}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Vendor</Header.Subheader>
                                  {item.vendor_id && vendors && vendors.length > 0 ? vendors.find(e => e.id === item.vendor_id).name : '-'}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={item.delete_at ? 7 : 14}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Date Created</Header.Subheader>
                                  {toReadableDateTime(item.created_at)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            {!!item.deleted_at &&
                                <Grid.Column width={7}>
                                  <Header as='h5'>
                                    <Header.Content>
                                      <Header.Subheader>Delete At</Header.Subheader>
                                      {toReadableDateTime(item.deleted_at)}
                                    </Header.Content>
                                  </Header>
                                </Grid.Column>}
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={5}>
                              <div className='ui two buttons'>
                                <Button basic color={item.enabled ? 'green' : 'red'}
                                        loading={goalIndex === `${key}-${i}`}
                                        disabled={goalIndex === `${key}-${i}`}
                                        onClick={() => handleGoalFreeze(goals,`${key}-`,i)}
                                >
                                  {item.enabled ? 'Freeze Goal' : 'Unfreeze Goal'}
                                </Button>
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={14}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Transactions</Header.Subheader>
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          {((popUp === NotifyCode.GOAL.SUCCESS || popUp === NotifyCode.GOAL.ERROR)) && <Grid.Row>
                            <Grid.Column width={14}>
                              <Message success={popUp === NotifyCode.GOAL.SUCCESS}
                                       negative={popUp === NotifyCode.GOAL.ERROR}>
                                <Message.Header>{popUpMessage}</Message.Header>
                              </Message>
                            </Grid.Column>
                          </Grid.Row>}
                          {
                            // userCan(currentUser.access, userLevels.OPEN_CLOSE_TRANSACTION) &&
                            <Grid.Row>
                              {goalTransactionState === item.id ? <Grid.Column width={14}>
                                <Button basic color="red" content="Cancel" loading={submitLoader}
                                        disabled={submitLoader}
                                        onClick={handleCancelGoalTransactionState}/>
                                <Button basic color="green" content="Save" loading={submitLoader}
                                        disabled={submitLoader}
                                        onClick={handleSaveGoalTransactionState}/>
                              </Grid.Column> : <Grid.Column width={14}>
                                <Button content="Edit State (Open/close)"
                                        onClick={() => setGoalTransactionState(item.id)}/>
                              </Grid.Column>}
                            </Grid.Row>}
                        </Grid>
                        {item.transactions && item.transactions.length ? <>
                              <Accordion.Accordion>
                                {goalTransactionState === item.id && <Grid>
                                  <Grid.Row>
                                    <Grid.Column width={14}>
                                      <Button content="Close All" disabled={submitLoader} loading={submitLoader}
                                              onClick={() => handleAllGoalTransactionState(item.transactions, 'closed')}/>
                                      <Button content="Open All" loading={submitLoader} disabled={submitLoader}
                                              onClick={() => handleAllGoalTransactionState(item.transactions, 'open')}/>
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>}
                                <Tab panes={goalTransactionsPanes(item)} style={{overflow: 'auto hidden'}}/>
                              </Accordion.Accordion>
                            </>
                            :
                            <Container text textAlign="center" content="No transaction records found"/>
                        }
                      </Accordion.Content>
                    </React.Fragment>
                )
                }
              </Accordion>
            </>
          }
        </Tab.Pane>
      })
    })
    if(products && products.length > 0){
      products.filter(e=> !showHide.includes(e.id)).forEach(e=> {
        const goals = value.filter(goal=> goal.product_id === e.id)
        const transactions = []
        goals.filter(e=>e.transactions && e.transactions.length > 0).forEach(e=>
            transactions.push(...e.transactions)
        )
        const key = e.id
        return productsPanes.push({
          menuItem:<Menu.Item key={key}>
            {e.name} ({goals.length})
          </Menu.Item>, render: () => <Tab.Pane>
            {goals.length === 0 ? <>
              No goal available
            </>:
            <>
              <Grid centered>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <Header as='h5'>
                      <Header.Content>
                        <Header.Subheader>Total Product Goal Deposits</Header.Subheader>
                        {toReadableValue(getTotalGoalTransactionDeposit(transactions ?? []), goals[0].currency_code)} {currencyCode(goals[0].currency_code)}
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Header as='h5'>
                      <Header.Content>
                        <Header.Subheader>Total Product Goal Withdrawable</Header.Subheader>
                        {toReadableValue(getTotalGoalTransactionWithdrawable(transactions ?? []), goals[0].currency_code)} {currencyCode(goals[0].currency_code)}
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Header as='h6'>
                      <Header.Content>
                        <Header.Subheader>Daily Product Goal Interest <Icon name="chevron up"
                                                                    color="blue"/></Header.Subheader>
                        {toReadableValue(getAllGoalTransactionInterest(getItemsByDateRange(transactions?? [])), goals[0].currency_code)} {currencyCode(goals[0].currency_code)}
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Header as='h6'>
                      <Header.Content>
                        <Header.Subheader>All Product Goal Deposits <Icon name="level up alternate"
                                                                  color="green"/></Header.Subheader>
                        {toReadableValue(getAllGoalTransactionDeposit(transactions ?? 0), goals[0].currency_code)} {currencyCode(goals[0].currency_code)}
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Header as='h6'>
                      <Header.Content>
                        <Header.Subheader>All Product Goal Withdrawals <Icon name="level down alternate"
                                                                     color="red"/></Header.Subheader>
                        {toReadableValue(getAllGoalTransactionWithdrawals(transactions ?? 0), goals[0].currency_code)} {currencyCode(goals[0].currency_code)}
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Header as='h6'>
                      <Header.Content>
                        <Header.Subheader>All Product Goal Withdrawal Charges <Icon name="level down alternate"
                                                                            color="red"/></Header.Subheader>
                        {toReadableValue(getAllGoalTransactionWithdrawalCharges(transactions ?? 0), goals[0].currency_code)} {currencyCode(goals[0].currency_code)}
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Header as='h6'>
                      <Header.Content>
                        <Header.Subheader>All Product Goal Interest <Icon name="chevron up"
                                                                  color="blue"/></Header.Subheader>
                        {toReadableValue(getAllGoalTransactionInterest(transactions ?? 0), goals[0].currency_code)} {currencyCode(goals[0].currency_code)}
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Divider hidden/>
              <Accordion fluid styled>
                {goals.map((item, i) =>
                    <React.Fragment key={item.id}>
                      <Accordion.Title
                          active={activeGoalIndex.includes(`${key}-${i}`)}
                          index={`${key}-${i}`}
                          onClick={handleActiveGoal}
                      >
                        <Icon name='dropdown'/>
                        {item.name}
                        <small>(Transactions({item.transactions ? item.transactions.length : 0}))</small>
                        {!!item.deleted_at && <>{' '} <Label color="red" content="Deleted"/></>}
                        {' '} | Balance <Label basic color="blue" content={`${toReadableValue(getTotalGoalTransactionDeposit(item.transactions ?? 0), item.currency_code)} ${currencyCode(item.currency_code)}`}/>
                        {' '} | Daily Interest <Label basic color="green" content={`${toReadableValue(getAllGoalTransactionInterest(getItemsByDateRange(item.transactions?? [])), item.currency_code)} ${currencyCode(item.currency_code)}`}/>
                        {item.target > 0 && <>
                          {' '} | Target <Label basic color={goalTargetPercentage(getTotalGoalTransactionDeposit(item.transactions ?? 0),item.target) >= 80 ? "green" : goalTargetPercentage(getTotalGoalTransactionDeposit(item.transactions ?? 0),item.target) >= 50 ? "yellow":"red"} content={`${goalTargetPercentage(getTotalGoalTransactionDeposit(item.transactions ?? 0),item.target)}%`}/>
                        </>}
                        {item.locked && <>{' | '} <Icon name="lock" color="orange"/></>}
                      </Accordion.Title>
                      <Accordion.Content active={activeGoalIndex.includes(`${key}-${i}`)}>
                        <Image
                            floated='right'
                            size='small'
                            src={item.img_url}
                        />
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={14}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>ID</Header.Subheader>
                                  {item.id}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={5}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Total Goal Deposits</Header.Subheader>
                                  {toReadableValue(getTotalGoalTransactionDeposit(item.transactions ?? 0), item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={5}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Total Goal Withdrawable</Header.Subheader>
                                  {toReadableValue(getTotalGoalTransactionWithdrawable(item.transactions ?? 0), item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Header as='h6'>
                                <Header.Content>
                                  <Header.Subheader>Daily Goal Interest <Icon name="chevron up"
                                                                              color="blue"/></Header.Subheader>
                                  {toReadableValue(getAllGoalTransactionInterest(getItemsByDateRange(item.transactions?? [])), item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={4}>
                              <Header as='h6'>
                                <Header.Content>
                                  <Header.Subheader>All Goal Deposits <Icon name="level up alternate"
                                                                            color="green"/></Header.Subheader>
                                  {toReadableValue(getAllGoalTransactionDeposit(item.transactions ?? 0), item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Header as='h6'>
                                <Header.Content>
                                  <Header.Subheader>All Goal Withdrawals <Icon name="level down alternate"
                                                                               color="red"/></Header.Subheader>
                                  {toReadableValue(getAllGoalTransactionWithdrawals(item.transactions ?? 0), item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Header as='h6'>
                                <Header.Content>
                                  <Header.Subheader>All Goal Withdrawal Charges <Icon name="level down alternate"
                                                                                      color="red"/></Header.Subheader>
                                  {toReadableValue(getAllGoalTransactionWithdrawalCharges(item.transactions ?? 0), item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Header as='h6'>
                                <Header.Content>
                                  <Header.Subheader>All Goal Interest <Icon name="chevron up"
                                                                            color="blue"/></Header.Subheader>
                                  {toReadableValue(getAllGoalTransactionInterest(item.transactions ?? 0), item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={7}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Deposits ({item.recurrence})</Header.Subheader>
                                  {toReadableValue(item.deposits, item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={7}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Target</Header.Subheader>
                                  {toReadableValue(item.target, item.currency_code)} {currencyCode(item.currency_code)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={4}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Recurrence</Header.Subheader>
                                  {item.recurrence}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Enabled</Header.Subheader>
                                  <Label
                                      color={item.enabled ? 'green' : 'red'}
                                      size="medium" content={item.enabled ? 'True' : 'False'}/>
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Locked</Header.Subheader>
                                  <Label
                                      color={item.locked ? 'green' : 'red'}
                                      size="medium" content={item.locked ? 'True' : 'False'}/>
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={14}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Vendor</Header.Subheader>
                                  {item.vendor_id && vendors && vendors.length > 0 ? vendors.find(e => e.id === item.vendor_id).name : '-'}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={item.delete_at ? 7 : 14}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Date Created</Header.Subheader>
                                  {toReadableDateTime(item.created_at)}
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                            {!!item.deleted_at &&
                                <Grid.Column width={7}>
                                  <Header as='h5'>
                                    <Header.Content>
                                      <Header.Subheader>Delete At</Header.Subheader>
                                      {toReadableDateTime(item.deleted_at)}
                                    </Header.Content>
                                  </Header>
                                </Grid.Column>}
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={5}>
                              <div className='ui two buttons'>
                                <Button basic color={item.enabled ? 'green' : 'red'}
                                        loading={goalIndex === `${key}-${i}`}
                                        disabled={goalIndex === `${key}-${i}`}
                                        onClick={() => handleGoalFreeze(goals,`${key}-`,i)}
                                >
                                  {item.enabled ? 'Freeze Goal' : 'Unfreeze Goal'}
                                </Button>
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column width={14}>
                              <Header as='h5'>
                                <Header.Content>
                                  <Header.Subheader>Transactions</Header.Subheader>
                                </Header.Content>
                              </Header>
                            </Grid.Column>
                          </Grid.Row>
                          {((popUp === NotifyCode.GOAL.SUCCESS || popUp === NotifyCode.GOAL.ERROR)) && <Grid.Row>
                            <Grid.Column width={14}>
                              <Message success={popUp === NotifyCode.GOAL.SUCCESS}
                                       negative={popUp === NotifyCode.GOAL.ERROR}>
                                <Message.Header>{popUpMessage}</Message.Header>
                              </Message>
                            </Grid.Column>
                          </Grid.Row>}
                          {
                            // userCan(currentUser.access, userLevels.OPEN_CLOSE_TRANSACTION) &&
                            <Grid.Row>
                              {goalTransactionState === item.id ? <Grid.Column width={14}>
                                <Button basic color="red" content="Cancel" loading={submitLoader}
                                        disabled={submitLoader}
                                        onClick={handleCancelGoalTransactionState}/>
                                <Button basic color="green" content="Save" loading={submitLoader}
                                        disabled={submitLoader}
                                        onClick={handleSaveGoalTransactionState}/>
                              </Grid.Column> : <Grid.Column width={14}>
                                <Button content="Edit State (Open/close)"
                                        onClick={() => setGoalTransactionState(item.id)}/>
                              </Grid.Column>}
                            </Grid.Row>}
                        </Grid>
                        {item.transactions && item.transactions.length ? <>
                              <Accordion.Accordion>
                                {goalTransactionState === item.id && <Grid>
                                  <Grid.Row>
                                    <Grid.Column width={14}>
                                      <Button content="Close All" disabled={submitLoader} loading={submitLoader}
                                              onClick={() => handleAllGoalTransactionState(item.transactions, 'closed')}/>
                                      <Button content="Open All" loading={submitLoader} disabled={submitLoader}
                                              onClick={() => handleAllGoalTransactionState(item.transactions, 'open')}/>
                                    </Grid.Column>
                                  </Grid.Row>
                                </Grid>}
                                <Tab panes={goalTransactionsPanes(item)} style={{overflow: 'auto hidden'}}/>
                              </Accordion.Accordion>
                            </>
                            :
                            <Container text textAlign="center" content="No transaction records found"/>
                        }
                      </Accordion.Content>
                    </React.Fragment>
                )
                }
              </Accordion>
            </>}
          </Tab.Pane>,
          goalsLength: goals.length
        })
      })
      productsPanes.sort((a,b)=> b.goalsLength - a.goalsLength)
    }
    return [...panes,...productsPanes]
  }

  return (
      <>
        <TopBar name={props.title}
                actions={
                  // userCan(currentUser.access, userLevels.ADD_IFA) &&
                [
                  {render: () => <Button basic color="blue" content="Add an IFA user" onClick={handleAddIfaModelOpen}/>},
                ]
                }
        />
        <Tab panes={panes} activeIndex={activeTab}
             onTabChange={(e,{ activeIndex }) => setCurrentTab(activeIndex)}/>
        <SideModal
            open={addIfaModalOpen}
            onClose={handleAddIfaModelClose}
            title="New IFA user"
        >
          <Form onSubmit={handleAddIfaUserSubmit}>
            <Grid centered>
              {(popUp === NotifyCode.CREATE_IFA_USER.ERROR) && <Grid.Row>
                <Grid.Column width={14}>
                  <Message negative>
                    <Message.Header>{popUpMessage}</Message.Header>
                  </Message>
                </Grid.Column>
              </Grid.Row>}
              <Grid.Row>
                <Grid.Column width={12}>
                  <Form.Field>
                    <label>First Name</label>
                    <input type="text" value={ifaFirstName} required
                           onChange={event => setIfaFirstName(event.target.value)}/>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Form.Field>
                    <label>Middle Name</label>
                    <input type="text" value={ifaMiddleName}
                           onChange={event => setIfaMiddleName(event.target.value)}/>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Form.Field>
                    <label>Last Name</label>
                    <input type="text" value={ifaLastName} required
                           onChange={event => setIfaLastName(event.target.value)}/>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Form.Field>
                    <label>Email</label>
                    <input type="email" value={ifaEmail} required
                           onChange={event => setIfaEmail(event.target.value)}/>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Form.Field>
                    <label>Phone Number</label>
                    <input type="tel" value={ifaPhoneNumber} required
                           onChange={event => setIfaPhoneNumber(event.target.value)}/>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Form.Field>
                    <label>Referral Code</label>
                    <input type="tel" value={ifaReferralCode}
                           onChange={event => setIfaReferralCode(event.target.value)}/>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Button type='submit' loading={submitLoader} disabled={submitLoader}>Submit</Button>
            </Grid>
          </Form>
        </SideModal>
        {!!(users && users.length && user) &&
        <>
          <SideModal
              open={viewModalOpen}
              onClose={handleCloseViewModal}
              title={`${user.first_name}'s Details`}
              width={80}
          >
            <Tab panes={modalPanes(user)}/>
          </SideModal>
          <SideModal
              open={editModalOpen}
              onClose={handleCloseEditModal}
              title={`Update ${user.first_name}'s KYC Data`}
          >
            <Form onSubmit={handleKYCData}>
              <Grid centered>
                {(popUp === NotifyCode.UPDATE_KYC.ERROR) && <Grid.Row>
                  <Grid.Column width={14}>
                    <Message negative>
                      <Message.Header>{popUpMessage}</Message.Header>
                    </Message>
                  </Grid.Column>
                </Grid.Row>}
                <Grid.Row>
                  <Grid.Column width={14}>
                    <Header as='h5'>
                      <Header.Content>
                        <Header.Subheader>Current KYC State</Header.Subheader>
                        <Label
                            color={KYCStateColor(user.kyc_state)}
                            basic
                            size="medium"
                            content={KYCStateName(user.kyc_state)}/>
                      </Header.Content>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={14}>
                    <Form.Field>
                      <label>KYC State</label>
                      <Dropdown
                          placeholder='KYC State'
                          fluid
                          selection
                          value={kyc_state}
                          options={[
                            {key:'verified',text:'VERIFIED',value:'verified'},
                            {key:'pending_verification',text:'PENDING VERIFICATION',value:'pending_verification'},
                            {key:'data_incomplete',text:'FAILED',value:'data_incomplete'},
                            {key:'not_provided',text:'DATA NOT PROVIDED',value:'not_provided'},
                          ]}
                          onChange={(event, {value}) => setKyc_state(value)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                {/*{kyc_state === "verified" &&<Grid.Row>
                  <Grid.Column width={14}>
                    <Form.Field>
                      <Checkbox label="Skip Smile Check" checked={skip_smile_check}
                                onChange={(event, {checked}) => setSkip_smile_check(checked)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>}*/}

                <Grid.Row>
                  <Grid.Column width={14}>
                    <Form.Field>
                      <label>KYC reason</label>
                      {/*<textarea onChange={event => setFailure_reason(event.target.value)}
                              value={failure_reason} rows={3}/>*/}
                      <Dropdown
                          placeholder='KYC reason'
                          fluid
                          clearable
                          search
                          selection
                          value={failure_reason}
                          options={getKYCReasonList()}
                          onChange={(event, {value}) => setFailure_reason(value)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                {kra_pin !== null && <Grid.Row>
                  <Grid.Column width={14}>
                    <Form.Field>
                      <label>
                        KRA Pin
                      </label>
                      <input placeholder='KRA Pin' defaultValue={kra_pin} onChange={event => setKra_pin(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>}
                <Button type='submit' loading={submitLoader} disabled={submitLoader}>Submit Changes</Button>
              </Grid>
            </Form>
          </SideModal>
          <SideModal
              open={documentModalOpen}
              onClose={handleCloseDocumentModal}
              title={`Upload ${user.first_name}'s KYC Documents`}
          >
            <Form onSubmit={handleUploadDocument}>
              <Grid centered>
                {(popUp === NotifyCode.UPDATE_KYC.ERROR) && <Grid.Row>
                  <Grid.Column width={14}>
                    <Message negative>
                      <Message.Header>{popUpMessage}</Message.Header>
                    </Message>
                  </Grid.Column>
                </Grid.Row>}
                <Grid.Row>
                  <Grid.Column width={14}>
                    <Form.Field>
                      <label>ID Type</label>
                      <Dropdown
                          placeholder='ID Type'
                          fluid
                          selection
                          value={id_type}
                          options={[
                            {key:'passport',text:'Passport',value:'passport'},
                            {key:'alien_card',text:'Alien card',value:'alien_card'},
                            {key:'national_id',text:'National ID',value:'national_id'},
                            {key:'selfie',text:'Selfie',value:'selfie'},
                          ]}
                          onChange={(event, {value}) => {
                            if(value === "selfie"){
                              setDocumentFront(null)
                              setDocumentBack(null)
                            }else{
                              setSelfie(null)
                            }
                            setId_type(value)}
                          }
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                {id_type === "selfie" ? <Grid.Row>
                  <Grid.Column width={14}>
                    <Form.Field>
                      <label>Selfie</label>
                      {!!selfie &&<>
                        <p>Preview</p>
                        <Image src={URL.createObjectURL(selfie)} size="medium"/>
                        <Divider hidden/>
                      </>}
                      <Input type="file" accept="image/jpg,image/jpeg,image/png" required
                             onChange={(e)=>handleSetSelfie(e.target.files[0])}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>:  id_type !== "" ?<>
                <Grid.Row>
                  <Grid.Column width={7}>
                    <Form.Field>
                      <label>Front</label>
                      {!!documentFront &&<>
                        <p>Preview</p>
                        <Image src={URL.createObjectURL(documentFront)} size="medium"/>
                        <Divider hidden/>
                      </>}
                      <Input type="file" accept="image/jpg,image/jpeg,image/png" required
                             onChange={(e)=>handleSetDocumentFront(e.target.files[0])}/>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={7}>
                    <Form.Field>
                      <label>Back</label>
                      {!!documentBack &&<>
                        <p>Preview</p>
                        <Image src={URL.createObjectURL(documentBack)} size="medium"/>
                        <Divider hidden/>
                      </>}
                      <Input type="file" accept="image/jpg,image/jpeg,image/png" required
                             onChange={(e)=>handleSetDocumentBack(e.target.files[0])}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                </>: <>

                </>}
                {(!!id_type && !!(selfie || documentBack || documentFront)) &&
                <Button type='submit' loading={submitLoader} disabled={submitLoader}>Submit Changes</Button>}
              </Grid>
            </Form>
          </SideModal>
          <SideModal
              open={editUserModalOpen}
              onClose={handleCloseEditUserModal}
              title={`Update ${user.first_name}'s Profile`}
          >
            <Form onSubmit={handleUserUpdate}>
              <Grid centered>
                {(popUp === NotifyCode.UPDATE_USER.ERROR) && <Grid.Row>
                  <Grid.Column width={14}>
                    <Message negative>
                      <Message.Header>{popUpMessage}</Message.Header>
                    </Message>
                  </Grid.Column>
                </Grid.Row>}
                {user.id !== currentUser.id &&
                <Grid.Row>
                  <Grid.Column width={14} style={{marginTop: 7}}>
                    <Form.Field>
                      <label>Enabled</label>
                      <Dropdown
                          placeholder='Enabled'
                          fluid
                          selection
                          value={enabled}
                          options={[
                            {key: 'true', text: 'Yes', value: true},
                            {key: 'false', text: 'No', value: false},
                          ]}
                          onChange={(event, {value}) => setEnabled(value)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>}
                <Grid.Row >
                  <Grid.Column width={14}>
                    <Form.Field>
                      <label>First Name</label>
                      <input placeholder='First Name' value={first_name} onChange={event => setFirst_name(event.target.value)} required/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row >
                  <Grid.Column width={14}>
                    <Form.Field>
                      <label>Middle Name</label>
                      <input placeholder='Middle Name' value={middle_name} onChange={event => setMiddle_name(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row >
                  <Grid.Column width={14}>
                    <Form.Field>
                      <label>Last Name</label>
                      <input placeholder='Last Name' value={last_name} onChange={event => setLast_name(event.target.value)} required/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row >
                  <Grid.Column width={14}>
                    <Form.Field>
                      <label>Phone number</label>
                      <input placeholder='eg +254xxxxxxxxx' value={phone_number} onChange={event => setPhone_number(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={14}>
                    <Form.Field>
                      <label>Date of Birth</label>
                      <SemanticDatepicker
                          value={dob}
                          onChange={(event,{value}) => setDob(value)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row >
                  <Grid.Column width={14}>
                    <Form.Field>
                      <label>Affiliation</label>
                      <input placeholder='Affiliation' value={affiliation} onChange={event => setAffiliation(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                {kra_pin !== null && <Grid.Row>
                  <Grid.Column width={14}>
                    <Form.Field>
                      <label>
                        KRA Pin
                      </label>
                      <input placeholder='KRA Pin' value={kra_pin} onChange={event => setKra_pin(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>}
                <Grid.Row>
                  <Grid.Column width={14}>
                    <Form.Field>
                      <label>Vendor</label>
                      <Dropdown
                          placeholder='Vendor'
                          fluid
                          selection
                          value={vendorId}
                          options={vendors && vendors ?
                              [...vendors.map(item => ({key: item.id, text: item.name, value: item.id}))]
                              : []}
                          onChange={(event, {value}) => setVendorId(value)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                {/*<Grid.Row>
                  <Grid.Column width={14}>
                    <Header as='h3' color="teal" content="Vendor" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={7}>
                    <Form.Field>
                      <label>Account name</label>
                      <input value={account_name} onChange={event => setAccount_name(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={7}>
                    <Form.Field>
                      <label>Account</label>
                      <input value={account} onChange={event => setAccount(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={7}>
                    <Form.Field>
                      <label>Bank</label>
                      <input value={bank} onChange={event => setBank(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={7}>
                    <Form.Field>
                      <label>Mobile money</label>
                      <input value={mobile_money} onChange={event => setMobile_money(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={7}>
                    <Form.Field>
                      <label>Apply Interest</label>
                      <Dropdown
                          placeholder='Apply Interest'
                          fluid
                          selection
                          value={apply_interest}
                          options={[
                            {key: 'true', text: 'Yes', value: true},
                            {key: 'false', text: 'No', value: false},
                          ]}
                          onChange={(event, {value}) => setApply_interest(value)}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={7}>
                    <Form.Field>
                      <label>Interest truncate limit</label>
                      <input type="number" value={interest_trunc_limit} onChange={event => setInterest_trunc_limit(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={7}>
                    <Form.Field>
                      <label>Deposit maturity</label>
                      <input value={deposit_maturity} onChange={event => setDeposit_maturity(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={7}>
                    <Form.Field>
                      <label>Deposit cut off</label>
                      <TimeInput
                          placeholder="HH:mm"
                          value={deposit_cut_off}
                          iconPosition="left"
                          onChange={(event, {value}) => setDeposit_cut_off(value)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={7}>
                    <Form.Field>
                      <label>Withdrawal maturity</label>
                      <input value={withdrawal_maturity} onChange={event => setWithdrawal_maturity(event.target.value)}/>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={7}>
                    <Form.Field>
                      <label>Withdrawal cut off</label>
                      <TimeInput
                          placeholder="HH:mm"
                          value={withdrawal_cut_off}
                          iconPosition="left"
                          onChange={(event, {value}) => setWithdrawal_cut_off(value)}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>*/}
                <Button type='submit' loading={submitLoader} disabled={submitLoader}>Submit Changes</Button>
              </Grid>
            </Form>
          </SideModal>
          <SideModal
              open={editReferralCodeModalOpen}
              onClose={handleCloseEditReferralModal}
              title={`Update ${user.first_name}'s Referral Data`}
          >
            <Form onSubmit={handleUserReferralUpdate}>
              <Grid centered>
                {(popUp === NotifyCode.UPDATE_REFERRAL.ERROR) && <Grid.Row>
                  <Grid.Column width={14}>
                    <Message negative>
                      <Message.Header>{popUpMessage}</Message.Header>
                    </Message>
                  </Grid.Column>
                </Grid.Row>}
                <Grid.Row >
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Code</label>
                      <input placeholder='Code' value={referral_code}
                             onChange={event => setReferral_Code(event.target.value)} required/>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Number Of Referral</label>
                    <input type="number" value={number_of_referrals || ''}
                           onChange={event => setNumber_of_referrals(Number(event.target.value))}/>
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={6}>
                    <Checkbox
                        toggle
                        label='Can Refer Others'
                        name="can_refer_others"
                        checked={can_refer_others === null ? true: can_refer_others}
                        onChange={(event, {checked})=> setCan_refer_others(checked)}/>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Redemption Mode</label>
                    <Dropdown
                        selection
                        placeholder='Redemption Mode'
                        value={redemption_mode}
                        options={[
                          {key: 'manual', text: 'Manual', value: 'manual'},
                          {key: 'automatic', text: 'Automatic', value: 'automatic'},
                        ]}
                        onChange={(event, {value}) =>
                            setRedemption_mode(value)}
                        style={{borderTopLeftRadius: 0, borderBottomLeftRadius:0}}
                    />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row >
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Earning Per Referral</label>
                    <input type="number" value={earning_per_referral || ''}
                           onChange={event => setEarning_per_referral(Number(event.target.value))}/>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Form.Field>
                      <label>Referral Currency</label>
                    <Dropdown
                        selection
                        placeholder='Referral Currency'
                        value={referral_currency}
                        options={currencyOptions}
                        onChange={(event, {value}) =>
                            setReferral_currency(value)}
                        style={{borderTopLeftRadius: 0, borderBottomLeftRadius:0}}
                    />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                <Button type='submit' loading={submitLoader}
                        disabled={submitLoader}>
                  Submit Changes</Button>
              </Grid>
            </Form>
          </SideModal>
          <SideModal
              open={usersKRAModalOpen}
              onClose={handleCloseUsersKRModal}
              title={`Check ${user.meta.kyc_data?.kra_pin} Duplicate`}
          >
            <Grid centered>
              <Grid.Row textAlign="right">
                <Grid.Column width={3} floated="right">
                  <Button content="Re-check" size="small" loading={appState === APP_STATE.LOADING_KRA_PIN_CHECKS} onClick={()=> handleSearchUsersKRA(user)}/>
                </Grid.Column>
              </Grid.Row>
              {([NotifyCode.KRA_PIN.SUCCESS,NotifyCode.KRA_PIN.ERROR].includes(popUp)) && <Grid.Row>
                <Grid.Column width={14}>
                  <Message success={[NotifyCode.KRA_PIN.SUCCESS].includes(popUp)} negative={[NotifyCode.KRA_PIN.ERROR].includes(popUp)}>
                    <Message.Header>{popUpMessage}</Message.Header>
                  </Message>
                </Grid.Column>
              </Grid.Row>}
            </Grid>
            {searchUsersKRA.length ?
                <Table singleLine basic='very' striped fixed={!isLargeScreen()}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Id</Table.HeaderCell>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>KYC Status</Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {searchUsersKRA.map((item) =>
                        <Table.Row key={item.id}>
                          <Table.Cell>{item.id.substring(0,18)}...</Table.Cell>
                          <Table.Cell>{`${item.first_name} ${item.last_name}`} {(item.meta && item.meta.is_IFA) && <Label
                              color="blue"
                              size="medium"
                              content="IFA"/>}
                          </Table.Cell>
                          <Table.Cell>
                            <Label
                                color={KYCStateColor(item.kyc_state)}
                                basic
                                size="medium"
                                content={KYCStateName(item.kyc_state)}/>
                          </Table.Cell>
                          <Table.Cell>
                            <Button icon="external alternate" content='View Details' size="small" color={"green"} floated="right"
                                    onClick={()=>viewUser(item.id)}/>
                          </Table.Cell>
                        </Table.Row>
                    )
                    }
                  </Table.Body>
                </Table>
                :
                <Container text textAlign="center" content="No records found" style={{height: "37vh"}}/>
            }
          </SideModal>
          <SideModal
              open={usersIDNumberModalOpen}
              onClose={handleCloseUsersIDNumberModal}
              title={`Check ${user.meta.kyc_data?.id_number} Duplicate`}
          >
            <Grid centered>
              <Grid.Row textAlign="right">
                <Grid.Column width={3} floated="right">
                  <Button content="Re-check" size="small" loading={appState === APP_STATE.LOADING_ID_NUMBER_CHECKS} onClick={()=> handleSearchUsersIDNumber(user)}/>
                </Grid.Column>
              </Grid.Row>
              {(popUp === NotifyCode.ID_NUMBER.SUCCESS || popUp === NotifyCode.ID_NUMBER.ERROR) && <Grid.Row>
                <Grid.Column width={14}>
                  <Message success={popUp === NotifyCode.ID_NUMBER.SUCCESS} negative={popUp === NotifyCode.ID_NUMBER.ERROR}>
                    <Message.Header>{popUpMessage}</Message.Header>
                  </Message>
                </Grid.Column>
              </Grid.Row>}
            </Grid>
            {searchUsersKRA.length ?
                <Table singleLine basic='very' striped fixed={!isLargeScreen()}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Id</Table.HeaderCell>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>KYC Status</Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {searchUsersKRA.map((item) =>
                        <Table.Row key={item.id}>
                          <Table.Cell>{item.id.substring(0,18)}...</Table.Cell>
                          <Table.Cell>{`${item.first_name} ${item.last_name}`} {(item.meta && item.meta.is_IFA) && <Label
                              color="blue"
                              size="medium"
                              content="IFA"/>}
                          </Table.Cell>
                          <Table.Cell>
                            <Label
                                color={KYCStateColor(item.kyc_state)}
                                basic
                                size="medium"
                                content={KYCStateName(item.kyc_state)}/>
                          </Table.Cell>
                          <Table.Cell>
                            <Button icon="external alternate" content='View Details' size="small" color={"green"} floated="right"
                                    onClick={()=>viewUser(item.id)}/>
                          </Table.Cell>
                        </Table.Row>
                    )
                    }
                  </Table.Body>
                </Table>
                :
                <Container text textAlign="center" content="No records found" style={{height: "37vh"}}/>
            }
          </SideModal>
          <SideModal
              open={verificationEventModalOpen}
              onClose={handleCloseVerificationEventModal}
              title={`${user.fullName} verification events`}
          >
            {!!user.meta.kyc_data?.verification_requests && <Card.Group>{[...user.meta.kyc_data.verification_requests].reverse().map((item,i)=>
                <Card fluid key={i}>
                  <Card.Content>
                    <Card.Meta>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={7}>
                            <Header as='h4'>
                              <Header.Content>
                                <Header.Subheader>State</Header.Subheader>
                                <Label
                                    color={KYCStateColor(item.state)}
                                    size="medium"
                                    content={KYCStateName(item.state)}/>
                              </Header.Content>
                            </Header>
                          </Grid.Column>
                          <Grid.Column width={7}>
                            <Header as='h4'>
                              <Header.Content>
                                <Header.Subheader>Date</Header.Subheader>
                                {toReadableDateTime(item.date)}
                              </Header.Content>
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={14}>
                            <Header as='h4'>
                              <Header.Content>
                                <Header.Subheader>Reason</Header.Subheader>
                                {item.reason ?? '-'}
                              </Header.Content>
                            </Header>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Card.Meta>
                  </Card.Content>
                </Card>)}
            </Card.Group>}
          </SideModal>
          <Modal
              size="mini"
              open={claimsModalOpen}
              onClose={handleCloseClaimModal}
          >
            {((popUpList.includes(NotifyCode.FETCH_GOALS.ERROR))) && <Grid.Row>
              <Grid.Column width={14}>
                <Message success={popUp === NotifyCode.GOAL.SUCCESS} negative={popUp === NotifyCode.GOAL.ERROR || popUpList.includes(NotifyCode.FETCH_GOALS.ERROR)}>
                  <Message.Header>{NotifyCode.FETCH_GOALS.ERROR ? popUpMessageList[NotifyCode.FETCH_GOALS.ERROR] : popUpMessage}</Message.Header>
                </Message>
              </Grid.Column>
            </Grid.Row>}
            <Modal.Header>{`Set ${user.first_name} ${user.last_name} claims to 0`}</Modal.Header>
            <Modal.Content>
              <p>Are you sure?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button negative disabled={submitLoader} onClick={handleCloseClaimModal}>
                No
              </Button>
              <Button positive loading={submitLoader} disabled={submitLoader} onClick={handleClaimsSubmit}>
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
              size="mini"
              open={reEnrollModalOpen}
              onClose={handleCloseReEnrollModel}
          >
            <Modal.Header>{`Re-enroll smile for ${user.first_name} ${user.last_name}`}</Modal.Header>
            <Modal.Content>
              <p>Are you sure?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button negative disabled={submitLoader} onClick={handleCloseReEnrollModel}>
                No
              </Button>
              <Button positive loading={submitLoader} disabled={submitLoader} onClick={handleReEnrollUser}>
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
              size="mini"
              open={userFreezeModalOpen}
              onClose={handleCloseUserFreezeModal}
          >
            <Modal.Header>{`${user.frozen ? "Unfreeze":"Freeze"} ${user.first_name} ${user.last_name}`}</Modal.Header>
            <Modal.Content>
              <p>Are you sure?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button negative disabled={submitLoader} onClick={handleCloseUserFreezeModal}>
                No
              </Button>
              <Button positive loading={submitLoader} disabled={submitLoader} onClick={handleFreezeUser}>
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
              size="mini"
              open={resetBalanceModalOpen}
              onClose={handleCloseResetBalanceModal}
          >
            <Modal.Header>{`Reset Balance for ${user.first_name} ${user.last_name}`}</Modal.Header>
            <Modal.Content>
              <p>Are you sure?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button negative disabled={submitLoader} onClick={handleCloseResetBalanceModal}>
                No
              </Button>
              <Button positive loading={submitLoader} disabled={submitLoader} onClick={handleResetBalance}>
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
              size="mini"
              open={migrateToCoopModalOpen}
              onClose={handleCloseMigrateToCoopModal}
          >
            <Modal.Header>{`Migrate ${user.first_name} ${user.last_name} to COOP`}</Modal.Header>
            <Modal.Content>
              <p>Are you sure?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button negative disabled={submitLoader} onClick={handleCloseMigrateToCoopModal}>
                No
              </Button>
              <Button positive loading={submitLoader} disabled={submitLoader} onClick={handleMigrateToCoop}>
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
              size="mini"
              open={resetPhoneModalOpen}
              onClose={handleCloseResetPhoneModal}
          >
            <Modal.Header>{`Reset Phone Number for ${user.first_name} ${user.last_name}`}</Modal.Header>
            <Modal.Content>
              <p>Are you sure?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button negative disabled={submitLoader} onClick={handleCloseResetPhoneModal}>
                No
              </Button>
              <Button positive loading={submitLoader} disabled={submitLoader} onClick={handleResetPhone}>
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
              size="mini"
              open={deleteUserModalOpen}
              onClose={handleCloseDeleteUserModal}
          >
            <Modal.Header>{`Delete account ${user.first_name} ${user.last_name} `}</Modal.Header>
            <Modal.Content>
              <p>Are you sure?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button negative disabled={submitLoader} onClick={handleCloseDeleteUserModal}>
                No
              </Button>
              <Button positive loading={submitLoader} disabled={submitLoader} onClick={handleDeleteUser}>
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
              size="mini"
              open={reconcileTransactionModalOpen}
              onClose={handleCloseReconcileTransactionModal}
          >
            <Modal.Header>{`Trigger Reconciliation for ${user.first_name} ${user.last_name}`}</Modal.Header>
            <Modal.Content>
              <p>Are you sure?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button negative disabled={submitLoader} onClick={handleCloseReconcileTransactionModal}>
                No
              </Button>
              <Button positive loading={submitLoader} disabled={submitLoader} onClick={handleReconcileTransaction}>
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </>}
      </>
  );
}

export default Users;

