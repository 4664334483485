import {Responsive} from "semantic-ui-react";

export const currencyCode = (code)=>{
  switch (code){
    case 'GHp':
      return 'GHS'
    case 'USc':
      return 'USD'
    /*case 'KE6':
      return 'KE6'*/
    default: return 'KSH'
  }
}

// export const toBaseValue = (value) => Number(value) * 100

export const toBaseValue = (value,code='KEc') =>{
  switch (code){
    case 'KE6':
      return Number(value) * 1000000
    default: return Number(value) * 100
  }
}

export const toBaseKESValue = (value,code='KEc') =>{
  switch (code){
    case 'KE6':
      return Number(value) / 10000
    default: return Number(value)
  }
}

export const toActualValue = (value,code='KEc') =>{
  return toBaseKESValue(value,code) / 100
}

export const toReadableValue = (value,code) =>
  toActualValue(value,code).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

export const toReadableDateTime = (value) => new Date(value).toUTCString()

export const statusName = value => {
  switch (value){
    case 'pending':
      return 'Pending'
    case 'verified':
      return 'Verified'
    case 'cancelled':
      return 'Cancelled'
    case 'success':
      return 'Successful'
    case 'failure':
      return 'Failed'
    case 'failed':
      return 'Failed'
    case 'processed':
      return 'Processed'
    case 'unprocessed':
      return 'Unprocessed'
    default: return value
  }
}

export const statusColor = value => {
  switch (value){
    case 'pending':
      return 'yellow'
    case 'unprocessed':
      return 'yellow'
    case 'success':
      return 'green'
    case 'verified':
      return 'green'
    case 'processed':
      return 'green'
    case 'cancelled':
      return 'red'
    case 'failure':
      return 'red'
    case 'failed':
      return 'red'
    default:
      return 'grey'
  }
}

export const toBaseRate = (value) => Number(value) / 100

export const toBasePercentage = (value) =>parseFloat((Number(value) * 100).toFixed(4))

export const KYCStateName = (state) =>{
  switch (state){
    case 'verified':
      return 'VERIFIED';
    case 'success':
      return 'SUCCESSFUL';
    case 'pending_verification':
      return 'PENDING VERIFICATION';
    case 'data_incomplete':
      return 'FAILED';
    case 'failure':
      return 'FAILED KYC';
    case 'not_provided':
      return 'DATA NOT PROVIDED';
    case 'auto_verification_passed':
      return 'AUTO VERIFICATION';
    case 'needs_human_review':
      return 'NEEDS REVIEW';
    default: return 'NOT PROVIDED';
  }
}

export const KYCStateColor = (state) =>{
  switch (state){
    case 'verified':
      return 'green';
    case 'success':
      return 'green';
    case 'pending_verification':
      return 'yellow';
    case 'data_incomplete':
      return 'red';
    case 'failure':
      return 'red';
    case 'auto_verification_passed':
      return 'teal';
    case 'needs_human_review':
      return 'orange';
    default: return 'grey';
  }
}

export const isLargeScreen = ()=> window.innerWidth > Responsive.onlyLargeScreen.minWidth

export const searchOnTable = (value,data) => {
  // eslint-disable-next-line array-callback-return
  let isIncluded = data.some(e=>{
    if(e && e.toString().toLowerCase().includes(value.toLowerCase())){
      return true
    }
  })
  return isIncluded || value.length < 1
}

export const yesOrNoName = (value) => value ? 'YES' : 'NO'
export const yesOrNoColor = (value) => value ? 'green' : 'red'

export const editableName = value => value ? 'Editable' : 'Not Editable'
export const editableColor = value => value ? 'green' : 'yellow'

export const toDateTimeDifference = (from,to)=>{


  let delta = Math.abs(to - from) / 1000;

// calculate (and subtract) whole days
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;


// calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

// calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

// what's left is seconds
  const seconds = delta % 60;

  return {days,hours,minutes,seconds}
}

export const AppConfigKeys = {
  //Users
  KycProcessing: "kyc_processing",
  NewUserRegistration: "registration",
  Login: "login",
  ProfileUpdate: "profile_update",
  //Transfers
  InboundTransfers: "inbound_transfers",
  OutboundTransfers: "outbound_transfers",
  InternalTransfers: "internal_transfers",
  MinTransactionAmount: "min_trans_amount",
  MaxTransactionAmount: "max_trans_amount",
  //Goals
  GoalCreation: "goal_creation",
  //Communications
  OutboundEmails: "outbound_emails",
  OutboundPushNotifications: "outbound_pn",
  OutboundTextMessages: "outbound_txt",
  //Referrals
  EnableReferrals: "enable_referrals",
  ReferralClaims: "ref_claims",
  NumberOfReferralClaims: "number_of_referral_claims",
  CanReferOthers: "can_refer_others",
  NumberOfReferrals: "number_of_referrals",
  EarningPerReferral: "earning_per_referral",
  ReferralCurrency: "referral_currency",
  RedemptionMode: "redemption_mode",
  MinimumReferralDepositKEC: "minimum_referral_deposit_kec",
  MinimumReferralDepositKSH: "minimum_referral_deposit_ksh",
  //Britam
  BritamCreateAccount: "bt_create_account",
  BritamWithdraw: "bt_withdraw",
  BritamDeposit: "bt_deposit",
}

export const getTransactionDirection = (value) =>{
  switch (value) {
    case 'inbound': return 'Deposit'
    case 'outbound': return 'Withdraw'
    default: return value
  }
}

export const transactionDirectionColor = (state) =>{
  switch (state){
    case 'inbound':
      return 'green';
    case 'outbound':
      return 'red';
    default: return 'blue';
  }
}

export const getTransactionType = (value) =>{
  switch (value) {
    case 'transfer': return 'Transfer'
    case 'interest': return 'Interest'
    default: return value
  }
}

export const transactionTypeColor = (value) =>{
  switch (value) {
    case 'transfer': return 'yellow'
    case 'interest': return 'blue'
    default: return 'red'
  }
}

export const sortItemsByDate = (data, order = 'desc') =>{
  return data
      .sort((a,b)=>
          order !== 'desc' ?
              new Date(a.created_at).getTime() - new Date(b.created_at).getTime() :
              new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
}

export const getItemsByDateRange = (data , startDate = new Date(new Date().setHours(0,0,0,0)).getTime()) =>{
  return data && data.filter(e=> new Date(e.created_at).getTime() >= startDate)
}

export const NotifyCode = {
  SUCCESS:'SUCCESS',
  ERROR:'ERROR',
  FETCH_USERS:{
    SUCCESS:'FETCH_USERS_SUCCESS',
    ERROR:'FETCH_USERS_ERROR',
  },
  FETCH_USER:{
    SUCCESS:'FETCH_USER_SUCCESS',
    ERROR:'FETCH_USER_ERROR',
  },
  FETCH_GOALS:{
    SUCCESS:'FETCH_GOALS_SUCCESS',
    ERROR:'FETCH_GOALS_ERROR',
  },
  FETCH_REFERRALS:{
    SUCCESS:'FETCH_REFERRALS_SUCCESS',
    ERROR:'FETCH_REFERRALS_ERROR',
  },
  CREATE_IFA_USER:{
    ERROR:'CREATE_IFA_USER_ERROR',
  },
  UPDATE_REFERRAL:{
    ERROR:'UPDATE_REFERRAL_ERROR',
  },
  UPDATE_KYC:{
    SUCCESS:'UPDATE_KYC_SUCCESS',
    ERROR:'UPDATE_KYC_ERROR',
  },
  UPDATE_USER:{
    ERROR:'UPDATE_USER_ERROR',
  },
  CLAIM:{
    SUCCESS:'CLAIM_SUCCESS',
    ERROR:'CLAIM_ERROR',
  },
  SMILE:{
    SUCCESS:'SMILE_SUCCESS',
    ERROR:'SMILE_ERROR',
  },
  PROFILE:{
    SUCCESS:'PROFILE_SUCCESS',
    ERROR:'PROFILE_ERROR',
  },
  GOAL:{
    SUCCESS:'GOAL_SUCCESS',
    ERROR:'GOAL_ERROR',
  },
  TRANSACTION_STATE:{
    SUCCESS:'TRANSACTION_STATE_SUCCESS',
    ERROR:'TRANSACTION_STATE_ERROR',
  },
  ADD_IFA:{
    SUCCESS:'ADD_IFA_SUCCESS',
    ERROR:'ADD_IFA_ERROR',
  },
  KRA_PIN:{
    SUCCESS:'KRA_PIN_SUCCESS',
    ERROR:'KRA_PIN_ERROR',
  },
  ID_NUMBER:{
    SUCCESS:'ID_NUMBER_SUCCESS',
    ERROR:'ID_NUMBER_ERROR',
  },

  //Category Page
  FETCH_CATEGORIES:{
    SUCCESS:'FETCH_CATEGORIES_SUCCESS',
    ERROR:'FETCH_CATEGORIES_ERROR',
  },
  CREATE_CATEGORY:{
    SUCCESS:'CREATE_CATEGORY_SUCCESS',
    ERROR:'CREATE_CATEGORY_ERROR',
  },
  UPDATE_CATEGORY:{
    SUCCESS:'UPDATE_CATEGORY_SUCCESS',
    ERROR:'UPDATE_CATEGORY_ERROR',
  },
  DELETE_CATEGORY:{
    SUCCESS:'DELETE_CATEGORY_SUCCESS',
    ERROR:'DELETE_CATEGORY_ERROR',
  },

  //Product Page
  FETCH_PRODUCTS:{
    SUCCESS:'FETCH_PRODUCTS_SUCCESS',
    ERROR:'FETCH_PRODUCTS_ERROR',
  },
  CREATE_PRODUCT:{
    SUCCESS:'CREATE_PRODUCT_SUCCESS',
    ERROR:'CREATE_PRODUCT_ERROR',
  },
  UPDATE_PRODUCT:{
    SUCCESS:'UPDATE_PRODUCT_SUCCESS',
    ERROR:'UPDATE_PRODUCT_ERROR',
  },
  DELETE_PRODUCT:{
    SUCCESS:'DELETE_PRODUCT_SUCCESS',
    ERROR:'DELETE_PRODUCT_ERROR',
  },

  //Vendor Page
  FETCH_VENDORS:{
    SUCCESS:'FETCH_VENDORS_SUCCESS',
    ERROR:'FETCH_VENDORS_ERROR',
  },
  CREATE_VENDOR:{
    SUCCESS:'CREATE_VENDOR_SUCCESS',
    ERROR:'CREATE_VENDOR_ERROR',
  },
  UPDATE_VENDOR:{
    SUCCESS:'UPDATE_VENDOR_SUCCESS',
    ERROR:'UPDATE_VENDOR_ERROR',
  },
  DELETE_VENDOR:{
    SUCCESS:'DELETE_VENDOR_SUCCESS',
    ERROR:'DELETE_VENDOR_ERROR',
  },

  //Job Page
  FETCH_JOBS:{
    SUCCESS:'FETCH_JOBS_SUCCESS',
    ERROR:'FETCH_JOBS_ERROR',
  },
  DELETE_JOB:{
    SUCCESS:'DELETE_JOB_SUCCESS',
    ERROR:'DELETE_JOB_ERROR',
  },

  //App Config Page
  FETCH_APP_CONFIGS:{
    SUCCESS:'FETCH_APP_CONFIGS_SUCCESS',
    ERROR:'FETCH_APP_CONFIGS_ERROR',
  },
  UPDATE_APP_CONFIG:{
    SUCCESS:'UPDATE_APP_CONFIG_SUCCESS',
    ERROR:'UPDATE_APP_CONFIG_ERROR',
  },
}

export const userLevels = {
  //User
  VIEW_USERS: 'view users',
  VIEW_USER_DETAILS: 'view user details',
  VIEW_SMILE_CHECKS: 'view smile checks',
  VIEW_USER_GOALS: 'view user goals',
  UPDATE_USER: 'update user',
  FREEZE_USER_ACCOUNT: 'freeze user account',
  RESET_USER_BALANCE: 'reset user balance',
  UPLOAD_USER_KYC_DOCUMENT: 'upload user kyc document',
  UPDATE_USER_KYC_DATA: 'update user kyc data',
  ENROLL_USER_SMILE: 'enroll user smile',
  CHECK_USER_SMILE: 'check user smile',
  UPDATE_USER_REFERRAL_DATA: 'update user referral data',
  CLAIM_USER_REFERRAL: 'claim user referral',
  RECON_USER_TRANSACTION: 'recon user',
  RESET_USER_PHONE: 'reset phone',
  DELETE_USER: 'delete user',
  ADD_IFA: 'add IFA',
  //Categories
  VIEW_CATEGORIES: 'view categories',
  VIEW_CATEGORY_DETAILS: 'view category details',
  CREATE_CATEGORY: 'create category',
  EDIT_CATEGORY: 'edit category',
  DELETE_CATEGORY: 'delete category',
  //Products
  VIEW_PRODUCTS: 'view products',
  VIEW_PRODUCT_DETAILS: 'view product details',
  CREATE_PRODUCT: 'create product',
  CREATE_NEW_PRODUCT: 'create new product',
  EDIT_PRODUCT: 'edit products',
  DELETE_PRODUCT: 'delete products',
  //Vendors
  VIEW_VENDORS: 'view vendors',
  VIEW_VENDOR_DETAILS: 'view vendor details',
  CREATE_VENDOR: 'create vendor',
  EDIT_VENDOR: 'edit vendor',
  //Transactions
  VIEW_TRANSACTIONS: 'view transactions',
  VIEW_TRANSACTION_DETAILS: 'view transaction details',
  UPDATE_TRANSACTION: 'update transaction',
  OPEN_CLOSE_TRANSACTION: 'open or close transaction',
  //Jobs
  VIEW_JOBS: 'view jobs',
  VIEW_JOB_DETAILS: 'view job details',
  DELETE_JOB: 'delete job',
  //Exports
  VIEW_EXPORTS: 'view exports',
  VIEW_EXPORT_DETAILS: 'view export details',
  CREATE_EXPORT: 'create export',
  START_EXPORT_PROCESS: 'start export proces',
  DOWNLOAD_EXPORT: 'download export',
  //App Configs
  VIEW_APP_CONFIGS: 'view app configs',
  UPDATE_APP_CONFIGS: 'update app configs',
}

const userAccess = {
  SUPER_USER: 'super_user',
  ADMIN: {
    name: 'admin',
    levels:[userLevels.VIEW_USERS,userLevels.VIEW_USER_DETAILS,userLevels.UPDATE_USER,userLevels.FREEZE_USER_ACCOUNT,
      userLevels.RESET_USER_PHONE,userLevels.DELETE_USER,userLevels.ADD_IFA,userLevels.VIEW_SMILE_CHECKS,
      userLevels.RESET_USER_BALANCE, userLevels.UPLOAD_USER_KYC_DOCUMENT,userLevels.UPDATE_USER_KYC_DATA,
      userLevels.ENROLL_USER_SMILE,userLevels.CHECK_USER_SMILE,userLevels.VIEW_USER_GOALS,
      userLevels.UPDATE_USER_REFERRAL_DATA,userLevels.CLAIM_USER_REFERRAL, userLevels.RECON_USER_TRANSACTION,
      userLevels.VIEW_CATEGORIES,userLevels.VIEW_CATEGORY_DETAILS,userLevels.CREATE_CATEGORY,userLevels.EDIT_CATEGORY,
      userLevels.DELETE_CATEGORY,
      userLevels.VIEW_PRODUCTS,userLevels.VIEW_PRODUCT_DETAILS,userLevels.CREATE_PRODUCT,userLevels.CREATE_NEW_PRODUCT,
      userLevels.EDIT_PRODUCT,userLevels.DELETE_PRODUCT,
      userLevels.VIEW_VENDORS,userLevels.VIEW_VENDOR_DETAILS,userLevels.CREATE_VENDOR,userLevels.EDIT_VENDOR,
      userLevels.VIEW_TRANSACTIONS,userLevels.VIEW_TRANSACTION_DETAILS,userLevels.UPDATE_TRANSACTION,
      userLevels.OPEN_CLOSE_TRANSACTION,
      userLevels.VIEW_JOBS,userLevels.VIEW_JOB_DETAILS,userLevels.DELETE_JOB,
      userLevels.VIEW_EXPORTS,userLevels.VIEW_EXPORT_DETAILS,userLevels.CREATE_EXPORT,userLevels.START_EXPORT_PROCESS,
      userLevels.DOWNLOAD_EXPORT,
      userLevels.VIEW_APP_CONFIGS,userLevels.UPDATE_APP_CONFIGS,]
  },
  INTERACTIVE_USER: {
    name: 'interactive_user',
    levels: [userLevels.VIEW_USERS,userLevels.VIEW_USER_DETAILS,userLevels.UPDATE_USER,
      userLevels.RESET_USER_PHONE,userLevels.ADD_IFA,userLevels.VIEW_SMILE_CHECKS,
      userLevels.RESET_USER_BALANCE, userLevels.UPLOAD_USER_KYC_DOCUMENT,userLevels.UPDATE_USER_KYC_DATA,
      userLevels.ENROLL_USER_SMILE,userLevels.CHECK_USER_SMILE,userLevels.VIEW_USER_GOALS,
      userLevels.UPDATE_USER_REFERRAL_DATA,userLevels.CLAIM_USER_REFERRAL, userLevels.RECON_USER_TRANSACTION,
      userLevels.VIEW_CATEGORIES,userLevels.VIEW_CATEGORY_DETAILS,userLevels.CREATE_CATEGORY,userLevels.EDIT_CATEGORY,
      userLevels.DELETE_CATEGORY,
      userLevels.VIEW_PRODUCTS,userLevels.VIEW_PRODUCT_DETAILS,userLevels.CREATE_PRODUCT,userLevels.CREATE_NEW_PRODUCT,
      userLevels.EDIT_PRODUCT,userLevels.DELETE_PRODUCT,
      // userLevels.VIEW_VENDORS,userLevels.VIEW_VENDOR_DETAILS,userLevels.CREATE_VENDOR,userLevels.EDIT_VENDOR,
      userLevels.VIEW_TRANSACTIONS,userLevels.VIEW_TRANSACTION_DETAILS,userLevels.UPDATE_TRANSACTION,
      userLevels.OPEN_CLOSE_TRANSACTION,
      userLevels.VIEW_JOBS,userLevels.VIEW_JOB_DETAILS,
      userLevels.VIEW_APP_CONFIGS,]
  },
  VIEWING_USER: {
    name: 'viewing_user',
    levels:[userLevels.VIEW_USERS,userLevels.VIEW_USER_DETAILS,userLevels.CHECK_USER_SMILE,
      userLevels.VIEW_PRODUCTS,userLevels.VIEW_PRODUCT_DETAILS,]
  },
  GUEST: { name: 'guest', levels:[]},
}

export const userCan = (access, level) => {
  switch (access) {
    case userAccess.SUPER_USER:
      return true
    case userAccess.ADMIN.name:
      return userAccess.ADMIN.levels.includes(level)
    case userAccess.INTERACTIVE_USER.name:
      return userAccess.INTERACTIVE_USER.levels.includes(level)
    case userAccess.VIEWING_USER.name:
      return userAccess.VIEWING_USER.levels.includes(level)
    case userAccess.GUEST.name:
      return userAccess.GUEST.levels.includes(level)
    default: return false
  }
}

export const formatCutOffDate = (cutOff) => {
  const date = new Date()
  const year = date.getFullYear();
  const month = (date.getMonth().toString().length < 2 && date.getMonth().toString() !== '9' ? '0' : '')
      + Number(date.getMonth() + 1)
  const day = (date.getDate().toString().length < 2 ? '0' : '') + date.getDate()
  return cutOff ? `${year}-${month}-${day}T${cutOff}:00Z` : null
}

export const handleSetPopupMessage = (setPopUp,setPopUpMessage,resetPopUp,code,message,time=1500,list=null,messageList=null)=>{
  setPopUp(list ? [...list,code] : code)
  setPopUpMessage(messageList ? {...messageList, [code]:message} :message)
  if(time !== -1){
    hidePopupMessage(time,resetPopUp,list,code)
  }
}

const hidePopupMessage = (time,resetPopUp,list,code)=>{
  setTimeout(()=>{
    if (list) {
      resetPopUp(code)
    }
    else {
      resetPopUp()
    }
  },time??1500)
}

export const getUserFirstName = (user) => !!user.first_name ? user.first_name : user.meta?.first_name ?? ''
export const getUserLastName = (user) => !!user.last_name ? user.last_name : user.meta?.last_name ?? ''
export const getUserMiddleName = (user) => !!user.middle_name ? user.middle_name : ''

export const getUserFullName = (user)=> `${getUserFirstName(user).trim()} ${getUserMiddleName(user).trim().concat(' ')}${getUserLastName(user)}`?.trim()

export const goalTargetPercentage = (deposit,target) => ((Number(deposit)/Number(target))*100).toFixed(2)

export const showHideKeys = {
  LOCK_GOAL: 'LOCK_GOAL',
  NON_LOCK_GOAL: 'NON_LOCK_GOAL',
  DELETE_GOAL: 'DELETE_GOAL',
}

export const filterShowHideGoals = (goals = [],showHide=[]) =>
    goals.filter(e=> !(showHide.includes(e.vendor_id) ||showHide.includes(e.product_id) || (showHide.includes(showHideKeys.LOCK_GOAL) && e.locked) || (showHide.includes(showHideKeys.NON_LOCK_GOAL) && !e.locked) || (showHide.includes(showHideKeys.DELETE_GOAL) && !!e.deleted_at)))